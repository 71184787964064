module.exports = {
    language:{
        language:'English'
    },
    menuObj:{
        menuName1:'首頁',
        menuName2:'新技術',
        menuName3:'產品展示',
        menuName4:'企業背景',
        menuName5:'聯繫我們',
    },
    ContactUsObj:{
        Contact1:'聯繫電話：+852  4613  6101+852  9323  2240',
        Contact2:'微信：18923831515',
        Contact3:'郵箱：cnpassport.alibaba.com',
        Contact4:'位置地點:香港九龍旺角彌敦道728號-730號',
    },
    search:{
        name:'搜索關鍵詞'
    }
}