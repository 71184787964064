<template>
    <div>
        <div class="PcompanyBG">
            <img class="PcompanyBG" src="../../assets/PCimg/homeFileImg/PccompanyBGEN.jpg" alt="">
        </div>
        <div class="PcompanyBoxEN" v-if="$t('language.language') === 'Chinese'">
            <!-- <img class="PcompanyFooter2" src="../../assets/PCimg/homeFileImg/PcompanyimgEn2.jpg" alt=""> -->
            <div class="Pcompany_centerfoot">
                <h1 class="Pcompany_titleBox">NORTHSTAR</h1>
                <div class="Pcompany_footBox">
                    <p>Northstar trade co., limited is a full-service lab established in 2016 and it is located in Zhuhai China with around  80000 square feet. </p>
                    <p>It is a modernization and high technology enterprise with 600 employees integrated administration, customer service, </p>
                    <p>traditional and digital productions.</p>
                    <p>Our lab is proud to offer virtually every Crown&Bridge, Implants, Flexible& Acrylic Partials, Full Dentures and Orthodontic Appliances. </p>
                    <p>Our lab strives to do business with this simple thought in mind: to add value to our dental partner. We achieve this goal</p>
                    <p>by providing the consistent quality and reliable service. In the past 9 years,</p>
                    <p>our lab has built more than 20 loyal customers around the USA and European through the commitment to creating a high </p>
                    <p>quality products at competitive prices.</p>

                </div>
            </div>
            <img class="PcompanyFooter3" src="../../assets/PCimg/homeFileImg/PcompanyimgEN1.jpg" alt="">
         </div>
        <div v-else class="PcompanyBoxCN">
            <div class="Pcompany_centerfoot">
                <h1 class="Pcompany_titleBox">NORTHSTAR</h1>
                <div class="Pcompany_footBox">
                    <p>領行齒科(香港)有限公司成立於2016年</p>
                    <p>是一家專注歐美國家中高端客戶的企業，個性化定制固定義齒。活動義齒與正畸類的數字化產品的企業</p>
                    <p>位於中國珠海高科技工業園，面積80000余平方英尺，可容納800人的高科技企業</p>
                    <p>目前公司人員規模500余人，集辦公、生產、數字化製造為一體的現代化高科技企業，</p>
                    <p>我們通過穩定的品質和可靠的服務而實現，9年創造，立足美國和歐洲，擁有了20多個客戶，每年服務600000萬需求者。</p>
                </div>
                <div class="Pcompany_footerBox">
                    <div class="Pcompany_garden">
                        <p class="Pcomany_title1">8000+</p>
                        <p class="Pcomany_title2">廠房面積（平方米）</p>
                    </div>
                    <div class="Pcompany_garden">
                         <p class="Pcomany_title1">500+</p>
                        <p class="Pcomany_title2">現員工規模（人）</p>
                    </div>
                    <div class="Pcompany_garden">
                         <p class="Pcomany_title1">60000+</p>
                        <p>月產能（顆）</p>
                    </div>
                    <div class="Pcompany_garden">
                        <p class="Pcomany_title1">600+</p>
                        <p class="Pcomany_title2">2023預計員工（人）</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="PcompanyFooter">
            <img class="PcompanyFooter1" src="../../assets/PCimg/homeFileImg/Pcompanyimg.jpg" alt="">
        </div>
    </div>
</template>
<script>
export default {
    name:'Pcompanypage',
    data(){
        return{

        }
    }
}
</script>
<style lang="less">
.PcompanyBG{
    width: 100%;
    height: 364px;
    margin-bottom: 100px;
}
// .PcompanyBoxCN{
   
// }
 .Pcompany_titleBox{
        font-size: 58px;
        color: #000000;
    }
    .Pcompany_footBox{
        font-size: 24px;
    }
.Pcompany_footerBox{
    margin-top:150px;
    width: 1400px;
    margin: 150px auto;
    display: flex;
    justify-content:space-between;
    .Pcompany_garden{
        width: 220px;
        height: 220px;
        border-radius: 50%;
        background: #f3f3f3;
    
        .Pcomany_title1{
            text-align: center;
            width: 220px;
            font-size:34px;
            color:#355374;
            margin:70px 0 10px 0;
        }
        .Pcomany_title2{
             text-align: center;
            width: 220px;
            font-size:16px;
            color:#355374;
            margin:10px 0;
        }
    }
}
.PcompanyFooter2{
    width:1900px;
    height:770px;
}
.PcompanyFooter3{
    width:1900px;
    height:340px;
}
 .PcompanyFooter1{
        width:1900px;
        height: 830px;
    }
</style>


