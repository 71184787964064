<template>
    <div class="FooterBox">
        <div class="footerlogo" style="padding-top: 55px;">
            <img class="footerlogo" src="../../../assets/MobileImg/CompanyLogo.png" alt="">
        </div>
        <div>
            <ul class="footermenuTotalBox">
                <li class="footermenuBox" ><router-link to="/home">{{$t('menuObj.menuName1')}}</router-link></li>
                <div class="footerkongge"></div>
                <li class="footermenuBox" ><router-link to="/Pctechnologypage">{{$t('menuObj.menuName2')}}</router-link></li>
                <div class="footerkongge"></div>
                <li class="footermenuBox" ><router-link to="/PCproductInfo">{{$t('menuObj.menuName3')}}</router-link></li>
                <div class="footerkongge"></div>
                <li class="footermenuBox" ><router-link to="/Pcompanypage">{{$t('menuObj.menuName4')}}</router-link></li>
                <div class="footerkongge"></div>
                <li class="footermenuBox"><router-link to="/PcContactpage">{{$t('menuObj.menuName5')}}</router-link></li>
            </ul>
            <div class="footerLXBox">
                <div style="padding:40px 0 25px 0;">
                    <span>{{$t('ContactUsObj.Contact1')}}</span>
                    <span>{{$t('ContactUsObj.Contact2')}}</span>
                    <span>{{$t('ContactUsObj.Contact3')}}</span>
                </div>
                <span>{{$t('ContactUsObj.Contact4')}}</span>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name:'home',
    data(){
        return{

        }
    },
    mounted(){

    },
    methods:{

    }
}
</script>
<style lang="less">
.FooterBox{
    display: flex;
    justify-content: center;
    // width: 1920px;
    height: 250px;
    background: #607a9d;
    .footerlogo{
            display: inline-block;
            width: 220px;
            height:70px;
            margin-right: 80px;
        }
    .footermenuTotalBox{
        display: flex;
         padding-top: 55px;
        align-items: center;
        .footermenuBox{
            a{
            display: inline-block;
            font-size: 20px;
            color: white;
            margin-right: 40px;
            text-align: left;
            font-family: '微软雅黑';
            }
        }
        .footerkongge{
            display: inline-block;
            width: 3px;
            height: 22px;
            margin-right: 44px;
            background: white;
        }
    }
    .footerLXBox{
        width: 695px;
        font-size: 16px;
        color: white;
        text-align: left;
    }
}
</style>