<template>
    <div style="min-width:1900px;" class="PcBox">
        <Header/>
         <router-view></router-view>
         <div class="contactInfoBox1" :style="{right:isShow ? '-45px' : '0px'}">
            <ul>
                <li><img src="../../assets/PCimg/homeFileImg/prev_icon.png"></li>
                <li class="tel_icon"
                    @mouseover="mouseOver($event)"
                    @mouseleave="mouseLeave($event)"
                ><img src="../../assets/PCimg/homeFileImg/tel_icon.png"></li>
                <li><img src="../../assets/PCimg/homeFileImg/next_icon.png"></li>
            </ul>
         </div>
         <div class="contactInfoBox2" @click="Close">
                <img v-if="isShow" src="../../assets/PCimg/homeFileImg/show_btn.png">
                <img v-else src="../../assets/PCimg/homeFileImg/hide_btn.png">
        </div>
        <div class="contactInfoBox3" :style="{right:isFlag ? '45px' : '-200px'}">
            <p class="contactInfoBox3_opone">電話直呼</p>
            <p class="contactInfoBox3_number">18923831515</p>
        </div>
         <el-backtop></el-backtop>
         <Footer/> 
    </div>
</template>
<script>
import Header from '../../components/PCcomponents/Header/index.vue'
import Footer from '../../components/PCcomponents/Footer/index.vue'
export default {
    components:{Header,Footer},
    name:'PCHome',
    data(){
        return{
            isShow:false,
            isFlag:false,
        }
    },
    mounted(){

    },
    methods:{
        Close(){
            this.isShow = !this.isShow;
        },
        mouseOver(e){
            this.isFlag = true;
        },
        mouseLeave(e){
            this.isFlag = false;

        }
    }
}
</script>
<style lang="less">
.contactInfoBox1{
    position: fixed;
    width:45px;
    background: #C1BFC0;
    z-index: 99;
    right: 0;
    top:250px;
    transition: 0.4s;
    border-radius: 6px 0 0 6px;
    cursor: pointer;
    ul{
        padding: 5px 0;
        .tel_icon{
            margin: 10px 0;
        }
    }
}
.contactInfoBox2{
    position: fixed;
    width:35px;
    z-index: 99;
    right: 0;
    top:350px;
    cursor: pointer;
}
.contactInfoBox3{
    position: fixed;
    width:200px;
    background: #C1BFC0;
    z-index: 90;
    right: -200px;
    top:280px;
    color: white;
    border-radius: 6px 0 0 6px;
    .contactInfoBox3_opone{
        text-align: left;
        font-size: 18px;
        padding:5px 10px 10px 10px;
        border-bottom: 1px solid white;
    }
    .contactInfoBox3_number{
        font-size: 14px;
    }
}
</style>

    