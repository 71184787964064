module.exports = {
    language:{
        language:'Chinese'
    },
    menuObj:{
        menuName1:'HOME',
        menuName2:'TECHNIQUE',
        menuName3:'All ceramic series',
        menuName4:'porcelain series',
        menuName5:'Planting restoration',
        menuName6:'Orthodontic device',
        menuName7:'Active Repair',
        menuName8:'ENTERPRISE',
        menuName9:'CONTACT',
        menuName10:'Denture Precision',
        menuName11:'Product Introduction',
    },
    footerObj:{
        footerName1:'HOME',
        footerName2:'TECHNIQUE',
        footerName3:'PRODUCT',
        footerName4:'NUMBER',
    },
    productObj:{
        productType1:'All ceramic',
        productType2:'Porcelain',
        productType3:'Planting',
        productType4:'Orthodontic',
        productType5:'Active',
    },
    ContactUsObj:{
        Contact1:'phone number:+0085269499181',
        Contact2:'WeChat:18923831515',
        Contact3:'e-mail:cnpassport.alibaba.com',
        Contact4:'Location:UNIT C 2/F KWONG ON BANK  MONGKOK BRANCH BUILDING 728-730 NATHAN ROAD MONGKOK KL',
    }
}