<template>
    <div>
        <div class="headerTopBox">
            <div class="PCcompanylogoBox"><img class="PCcompanylogoBox" src="../../../assets/MobileImg/CompanyLogo.png" alt=""></div>
            <ul>
                <li class="menuBox" :class="[activeIndex === 0 ? 'active' :'']" @click="handleSelect(0)">
                    <router-link to="/home">
                        {{$t('menuObj.menuName1')}}
                    </router-link>
                </li>
                <div class="kongge"></div>
                <li class="menuBox" :class="[activeIndex === 1 ? 'active' :'']" @click="handleSelect(1)">
                    <router-link to="/Pctechnologypage">
                    {{$t('menuObj.menuName2')}}
                    </router-link>
                </li>
                <div class="kongge"></div>
                <li class="menuBox" :class="[activeIndex === 2 ? 'active' :'']" @click="handleSelect(2)">
                    <router-link to="/PCproductInfo">
                        {{$t('menuObj.menuName3')}}
                    </router-link>
                </li>
                <div class="kongge"></div>
                <li class="menuBox" :class="[activeIndex === 3 ? 'active' :'']" @click="handleSelect(3)">
                    <router-link to="/Pcompanypage">
                    {{$t('menuObj.menuName4')}}
                    </router-link>
                </li>
                <div class="kongge"></div>
                <li class="menuBox" :class="[activeIndex === 4 ? 'active' :'']" @click="handleSelect(4)">
                    <router-link to="/PcContactpage">
                        {{$t('menuObj.menuName5')}}
                    </router-link>
                </li>
                <li class="searchBox">
                    <el-input v-model="input"
                            style="height:30px;width: 160px;"
                            suffix-icon="el-icon-search"
                            :placeholder="$t('search.name')">
                    </el-input>
                </li>
                <li class="menulang" @mouseover="mouseOver($event)">EN ▼ </li>
            </ul>
            <div class="PClangBox" :style="{right:$t('language.language') === 'Chinese' ? '282px' : '365px'}" v-show="isShow">
                <p class="PClangBox_font" @click="changelang('English')">English</p>
                <p class="PClangBox_font" @click="changelang('Chinese')" style="margin-bottom:15px">中文</p>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data(){
        return{
            menuList:['首頁','新技術','產品展示','企業背景','聯繫我們'],
            activeIndex:0,
            isShow:false,
            input:''
        }
    },
    methods: {
        handleSelect(index){
            this.activeIndex = index;
        },
        mouseOver(e){
            this.isShow = !this.isShow;
        },
        changelang(v){
            if(v === 'Chinese'){
                this.$i18n.locale = 'zh',
                localStorage.setItem('languageSet',this.$i18n.locale)
            } else{
                this.$i18n.locale = 'en',
                localStorage.setItem('languageSet',this.$i18n.locale)
            }
            this.isShow = false;
        }
    }
}
</script>
<style lang="less">
.headerTopBox{
    display: flex;
    justify-content: center;
    width: 100%;
    height: 80px;
    max-width: 1920px;
    background:rgb(96, 122, 157);
    font-size: 16px;
    color:white;
    box-sizing: border-box;
    .PCcompanylogoBox{
            display: inline-block;
            padding-top: 5px;
            width: 175px;
            height:80x;
            margin-right:50px;
    }
     ul{  
         position: relative; 
         .menuBox{
            display: inline-block;
            height: 75px;
            line-height: 80px;
            cursor: pointer;
             a{
                 color: white;
            }
         }
         .menuBox:hover{
            border-bottom:5px solid white;
         }
         .kongge{
            display: inline-block;
            width:50px;
         }
         .active{
             border-bottom:5px solid white;
         }
         .menulang{
            display: inline-block;
            font-size: 18px;
            height: 75px;
            line-height: 80px;
            cursor: pointer;
            font-weight: bold; 
         }
     }
}
.el-carousel__item .headerImgBox{
    display: inline-block;
    color: #475669;
    font-size: 18px;
    width: 100%;
    height:870px;
}
.PClangBox{
    position: absolute;
    width: 120px;
    top: 80px;
    right:430px;
    z-index: 100;
    background:rgb(96, 122, 157);
    cursor: pointer;
    .PClangBox_font{
        margin:5px;
        height: 25px;
        line-height:25px;
    }
}
.searchBox{
    display: inline-block;
    margin-left: 150px;
    margin-right: 30px;
     cursor: pointer;
    .el-input{
        .el-input__inner{
            height: 30px;
            border-radius: 10px;
        }
        .el-input__icon{
            line-height: 30px;
        }
    }
}
</style>

