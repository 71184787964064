<template>
    <div>
        <div class="PcContactpageBG">
            <img class="PcContactpageBG" src="../../assets/PCimg/homeFileImg/PcContactpageBG.png" alt="">
        </div>
        <div class="PcContactBox">
            <div class="PcContactBox_left">
                <div>
                    <div>
                        <img class="ContactUs_img" src="../../assets/MobileImg/phone.png" alt="">
                        <span class="PcContactFoot">{{$t('ContactUsObj.Contact1')}}</span>
                    </div>
                    <div>
                        <img class="ContactUs_img" src="../../assets/MobileImg/wxlogo.png" alt="">
                        <span class="PcContactFoot">{{$t('ContactUsObj.Contact2')}}</span>
                    </div>
                    <div>
                        <img class="ContactUs_img" src="../../assets/MobileImg/emaillogo.png" alt="">
                        <span class="PcContactFoot">{{$t('ContactUsObj.Contact3')}}</span>
                    </div>
                    <div >
                        <img class="ContactUs_img" src="../../assets/MobileImg/maplogo.png" alt="">
                        <span style=" display: inline-block;width: 480px;" class="PcContactFoot">{{$t('ContactUsObj.Contact4')}}</span>
                    </div>
                </div>
            </div>  
            <div class="PcContactBox_right">
                <img class="PcContactBox_right" src="../../assets/PCimg/homeFileImg/PcContactpageMP.png" alt="">
            </div>  
        </div>

    </div>
</template>
<script>
export default {
    name:'',
    data(){
        return{

        }
    }
}
</script>
<style lang="less">
.PcContactpageBG{
    width: 100%;
    height: 364px;
    margin-bottom: 100px;
}
.PcContactBox{
    margin: 50px auto;
    width: 1300px;
    display: flex;
    .PcContactBox_left{
        display: flex;
        justify-content:center;
        align-items:center;
        width: 650px;
        text-align: left;
        vertical-align: top;
        background: rgb(213, 222, 233) none no-repeat left top;
    }
    .PcContactFoot{
        font-size: 20px;
        color: #444;
    }
    .PcContactBox_right{
        width: 650px;
    }
}
</style>

