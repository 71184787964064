<template>
    <div>
        <div class="ContactUs_header">
            <img class="ContactUs_header" src="../../assets/MobileImg/ContactUsbg.png" alt="">
        </div>
        <div class="ContactUs_center">
            <div style="margin-top:10px">
                <img class="ContactUs_img" src="../../assets/MobileImg/phone.png" alt="">
                <span class="ContactUs_phone">{{$t('ContactUsObj.Contact1')}}</span>
            </div>
            <div style="margin-top:10px">
                <img class="ContactUs_img" src="../../assets/MobileImg/wxlogo.png" alt="">
                <span>{{$t('ContactUsObj.Contact2')}}</span>
            </div>
            <div style="margin-top:10px">
                <img class="ContactUs_img" src="../../assets/MobileImg/emaillogo.png" alt="">
                <span>{{$t('ContactUsObj.Contact3')}}</span>
            </div>
            <div style="margin-top:10px">
                <img class="ContactUs_img" src="../../assets/MobileImg/maplogo.png" alt="">
                <span class="ContactUs_font">{{$t('ContactUsObj.Contact4')}}</span>
            </div>
        </div>
        <div class="ContactUs_footer">
            <img class="ContactUs_footer" src="../../assets/MobileImg/ContactUsMap.png" alt="">
        </div>
    </div>
</template>
<script>
export default {
    name:'ContactUs',
    data(){
        return{
            
        }
    }
}
</script>
<style lang="less">
.ContactUs_header{
    width: 100%;
    height: 7rem;
}
.ContactUs_center{
    width: 100%;
    padding: 20px 25px 40px 25px;
    vertical-align: middle;
    text-align: left;
    
}
.ContactUs_font{
    display: inline-block;
    vertical-align: top; 
    width: 15rem;
}
.ContactUs_phone{
    display: inline-block;
    vertical-align: top; 
    width: 15rem;
}
.ContactUs_footer{
    width: 100%;
    height: 15.2rem;
}
.ContactUs_img{
    margin-right:5px;
    width: 2rem;
    height: 2rem;
    vertical-align: middle;
}
</style>


