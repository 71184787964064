<template>
    <div class="heanderBox">
        <van-nav-bar title="测试" :safe-area-inset-top="true" @click-left="showNav">
            <template #title>
                <img class="logoBox" src="../../../assets/MobileImg/CompanyLogo.png" alt="">
            </template>
            <template #left>
                <van-icon name="wap-nav" size="18" color="white"/>
            </template>
        </van-nav-bar>
        <van-popup v-model="showModal" position="left">
            <div>
                <ul class="menuBox">
                    <li style="padding-top:10px;">
                        <router-link to="/home"  v-on:click.native="close">
                            <span class="leftBox1"><van-icon name="wap-home" color="white"/></span>{{$t('menuObj.menuName1')}}
                        </router-link>
                    </li>
                    <li>
                        <router-link to="/newTechniquePage"  v-on:click.native="close">
                            <span class="leftBox"><van-icon name="arrow" /></span>{{$t('menuObj.menuName2')}}
                        </router-link>
                    </li>
                    <li>
                        <router-link :to="{name:'productPage',query:{type:'productType1'}}" v-on:click.native="close">
                            <span class="leftBox"><van-icon name="arrow" />
                            </span>{{$t('menuObj.menuName3')}}
                        </router-link>
                    </li>
                    <li>
                        <router-link :to="{name:'productPage',query:{type:'productType2'}}" v-on:click.native="close">
                            <span class="leftBox"><van-icon name="arrow" /></span>{{$t('menuObj.menuName4')}}
                        </router-link>
                    </li>
                     <li>
                        <router-link to="/productAnnex" v-on:click.native="close">
                            <span class="leftBox"><van-icon name="arrow" /></span>{{$t('menuObj.menuName10')}}
                        </router-link>  
                    </li>
                    <li>
                        <router-link :to="{name:'productPage',query:{type:'productType3'}}" v-on:click.native="close">
                            <span class="leftBox"><van-icon name="arrow" /></span>{{$t('menuObj.menuName5')}}
                        </router-link>
                    </li>
                    <li>
                        <router-link :to="{name:'productPage',query:{type:'productType4'}}" v-on:click.native="close">
                            <span class="leftBox"><van-icon name="arrow" /></span>{{$t('menuObj.menuName6')}}
                        </router-link>
                    </li>
                    <li>
                        <router-link :to="{name:'productPage',query:{type:'productType5'}}" v-on:click.native="close">
                            <span class="leftBox"><van-icon name="arrow" />
                            </span>{{$t('menuObj.menuName7')}}
                        </router-link>
                    </li>
                    <li>
                        <router-link to="/CompanyPage" v-on:click.native="close">
                            <span class="leftBox"><van-icon name="arrow" /></span>{{$t('menuObj.menuName8')}}
                        </router-link>
                    </li>
                    <li>
                        <router-link to="/ContactUs" v-on:click.native="close">
                            <span class="leftBox"><van-icon name="arrow" /></span>{{$t('menuObj.menuName9')}}
                        </router-link>    
                    </li>
                    <li>
                        <router-link to="/productInfo" v-on:click.native="close">
                            <span class="leftBox"><van-icon name="arrow" /></span>{{$t('menuObj.menuName11')}}
                        </router-link>    
                    </li>
                    <li @click="changeLang($t('language.language'))"><span class="leftBox"><van-icon name="arrow" /></span>{{$t('language.language')}}</li>
                </ul>
            </div>
        </van-popup>
    </div>
</template>
<script>
export default {
    data(){
        return{
            showModal:false,
        }
    },
    mounted(){

    },
    methods:{
        showNav(){
            this.showModal = true;
        },
        close(){
            window.location.reload();
            this.showModal = false;
        },
        changeLang(v){
            if(v === 'Chinese'){
                this.$i18n.locale = 'zh',
                localStorage.setItem('languageSet',this.$i18n.locale)
            } else{
                this.$i18n.locale = 'en',
                localStorage.setItem('languageSet',this.$i18n.locale)
            }
            this.showModal = false;
        }
    }
}
</script>
<style lang="less">
.heanderBox{
    .logoBox{
        width:5rem;
        height:1.8rem;
    }
    .van-nav-bar{
        height: 40px;
        background-color: rgb(95 122 157);
        .van-nav-bar__title{
            color: white;
        }
        .van-nav-bar__content{
            height: 40px;
        }
    }
    .leftBox{
        color:#5b5d5e;
        font-weight:bold;
        margin-right: 5px;
    }
    .leftBox1{
        font-weight:bold;
        margin-right:10px;
    }
        .van-popup{
            width: 55%;
            height: 100%;
            background-color: #607a9d;
        }
        .menuBox{
            text-align: left;
            color: white;
        }
        .menuBox li{
            border-bottom: 1px solid white;
            padding:5px 2px;
            a{
                 color: white;
            }
        }
    
}
</style>


