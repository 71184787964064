<template>
    <div>
        <div class="Section_header">
            <img class="Section_header" src="../../assets/MobileImg/newTechniqueImg.png" alt="">
        </div>
         <div class="Section_box">
            <img class="Section_box" v-if="$t('language.language') === 'Chinese'" src="../../assets/MobileImg/newTechniqueCEN1.jpg" alt="banner1">
            <img class="Section_box" v-else src="../../assets/MobileImg/newTechniqueCN1.jpg" alt="banner1">
        </div>
        <div class="Section_footer">
            <div class="Section_footer_title">
                <img class="Section_footer_title" v-if="$t('language.language') === 'Chinese'" src="../../assets/MobileImg/newTechniquefEN1.jpg" alt="banner1">
                <img class="Section_footer_title" v-else src="../../assets/MobileImg/newTechniquefCN1.png" alt="banner1">
            </div>
            <div class="Section_footer_img1" >
                <img class="Section_footer_img1" src="../../assets/MobileImg/newTechniquefImg.jpg" alt="">
            </div>
            <div class="Section_footer_img1" style=" padding: 1.3rem;background: #f2f2f2;box-sizing: border-box;">
                <img class="Section_footer_img1" src="../../assets/MobileImg/newTechniquefImgEN1.png" alt="banner1">
            </div>
            <div class="Section_footer_img1">
                <img class="Section_footer_img1" src="../../assets/MobileImg/newTechniquefImg2.jpg" alt="">
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name:'newTechniquePage',
    data(){
        return{

        }
    }
}
</script>
<style>
    .Section_header{
        width: 100%;
        height: 7rem;
    }
    .Section_box{
        width: 100%;
        height:16.7rem;
    }
    .Section_footer_title{
        width: 100%;
        height:4.2rem;
    }
    .Section_footer_img1{
        width: 100%;
        height:12.2rem;
    }
</style>


