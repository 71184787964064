<template>
    <div class="productInfo_box">
        <div class="Section_header">
            <img class="Section_header" src="../../assets/MobileImg/pruductTopImg.png" alt="">
        </div>
        <ul v-if="$t('language.language') === 'Chinese'" >
            <li  v-for="(item,index) in productInfoListEN" :key="index" @click="linkRouter(item)">{{item.name}}</li>
        </ul>
        <ul v-else >
            <li  v-for="(item,index) in productInfoListCN" :key="index" @click="linkRouter(item)">{{item.name}}</li>
        </ul>
    </div>
</template>
<script>
export default {
    name:'productInfo',
    data(){
        return{
            productInfoListCN:[
                {name:'Ablaze全瓷系統',value:'productTypeCNimg1_2'},
                {name:'運動護齒套',value:'productTypeCNimg4_5'},
                {name:'护磨牙墊',value:'productTypeCNimg4_1'},
                {name:'防鼾器',value:'productTypeCNimg4_2'},
                {name:'法蘭克爾矯正器',value:'productTypeCNimg4_3'},
                {name:'螺旋矯正器',value:'productTypeCNimg4_6'},
                {name:'哈利氏保持器',value:'productTypeCNimg4_4'},
                {name:'Snap on smile',value:'productTypeCNimg5_6'},
                {name:'聚醚醚酮peek支架',value:'productTypeCNimg5_4'},
                {name:'全口義齒',value:'productTypeCNimg5_5'},
                {name:'德國BPD完美支架',value:'productTypeCNimg5_1'},
                {name:'維他靈支架',value:'productTypeCNimg5_2'},
                {name:'純鈦鋼托支架',value:'productTypeCNimg5_3'},
                {name:'馬里蘭橋',value:'productTypeCNimg2_5'},
                {name:'金屬烤瓷冠',value:'productTypeCNimg2_4'},
                {name:'生物鐳射烤瓷',value:'productTypeCNimg2_1'},
                {name:'LAVA全瓷系统 / Lava Full Ceramic',value:'productTypeCNimg1_6'},
                {name:'易美全瓷系統',value:'productTypeCNimg1_5'},
                {name:'威兰德全瓷系統/ WIELAND',value:'productTypeCNimg1_3'},
                {name:'金屬嵌體 / METAL INLAY',value:'productTypeCNimg2_2'},
                {name:'種植个性化基台',value:'productTypeCNimg3_3'},
                {name:'種植導板',value:'productTypeCNimg3_2'},
                {name:'種植覆蓋義齒',value:'productTypeCNimg3_1'},
                {name:'鑄造個性化種植支架',value:'productTypeCNimg3_4'},
                {name:'精密附件套筒冠',value:'productAnnexObj3'},
                {name:'精密附件快模式( Snap)',value:'productAnnexObj1'},
                {name:'精密附件栓道式 （Preci-line）',value:'productAnnexObj2'},
                {name:'精密附件根帽附著體（scop）',value:'productAnnexObj4'},
                {name:'精密附件杆卡附著體（BAR）',value:'productAnnexObj6'},
            ],
            productInfoListEN:[
                {name:'Ablaze Zirconia',value:'productTypeCNimg1_2'},
                {name:'Sport Guard',value:'productTypeCNimg4_5'},
                {name:'Night Guard',value:'productTypeCNimg4_1'},
                {name:'Snoring Guard',value:'productTypeCNimg4_2'},
                {name:'Frankel Appliance ',value:'productTypeCNimg4_3'},
                {name:'Expansion Plate',value:'productTypeCNimg4_6'},
                {name:'Hawley Retainer ',value:'productTypeCNimg4_4'},
                {name:'Snap on smile',value:'productTypeCNimg5_6'},
                {name:'Peek Framework',value:'productTypeCNimg5_4'},
                {name:'Full Denture',value:'productTypeCNimg5_5'},
                {name:'Bego Framework',value:'productTypeCNimg5_1'},
                {name:'Vitallium 2000 Framework',value:'productTypeCNimg5_2'},
                {name:'Titanium Framework',value:'productTypeCNimg5_3'},
                {name:'Maryland Bridge',value:'productTypeCNimg2_5'},
                {name:'Metal Print',value:'productTypeCNimg2_4'},
                {name:'Laser Print',value:'productTypeCNimg2_1'},
                {name:'Lava Zirconia',value:'productTypeCNimg1_6'},
                {name:'IPS e.max',value:'productTypeCNimg1_5'},
                {name:'Wieland Zirconia',value:'productTypeCNimg1_3'},
                {name:'Metal Inlay',value:'productTypeCNimg2_2'},
                {name:'Custom Abutment',value:'productTypeCNimg3_3'},
                {name:'Surgical Implant Guide',value:'productTypeCNimg3_2'},
                {name:'Implant Overdenture',value:'productTypeCNimg3_1'},
                {name:'Casting Superstructure For Removable Denture',value:'productTypeCNimg3_4'},
                {name:'Telescope Coping',value:'productAnnexObj3'},
                {name:'Snap',value:'productAnnexObj1'},
                {name:'Slot Attachment',value:'productAnnexObj2'},
                {name:'cop',value:'productAnnexObj4'},
                {name:'BAR',value:'productAnnexObj6'},
            ]
        }
    },
    methods:{
        linkRouter(item){
            this.$router.push({name:'productAnnexDet',query:{value:item.value}})
        }
    }
}
</script>
<style lang="less">
.productInfo_box{
    background: #f5f5f5;
    .Section_header{
        width: 100%;
        height: 7rem;
    }
    ul{
        li{
            margin: 10px 0;
            padding: 10px;
            text-align:left;
            font-size: 1rem;
            background: white;
        }
    }
}
</style>

