<template>
    <van-tabbar v-model="active" fixed :placeholder="true" active-color="#646566" z-index="99">
        <van-tabbar-item name="footer1" @click="LinkHome">
            <span>{{$t('footerObj.footerName1')}}</span>
            <template #icon="props">
                <img src="../../../assets/FooterLogo/homelogo.png"/>
            </template>
        </van-tabbar-item>
        <van-tabbar-item name="footer2" replace to="/newTechniquePage">
            <span>{{$t('footerObj.footerName2')}}</span>
            <template #icon="props">
                <img src="../../../assets/FooterLogo/footerlogo2.png"/>
            </template>
        </van-tabbar-item>
        <van-tabbar-item name="footer3" replace to="/productPage">
           <span> {{$t('footerObj.footerName3')}}</span>
            <template #icon="props">
                <img src="../../../assets/FooterLogo/footerlogo1.png"/>
            </template>
        </van-tabbar-item>
        <van-tabbar-item name="footer4">
            <span><a style="color:#646566" href="tel:0085246136101">{{$t('footerObj.footerName4')}}</a></span>
            <template #icon="props">
                <img src="../../../assets/FooterLogo/footerlogo3.png"/>
            </template>
        </van-tabbar-item>
        <van-tabbar-item name="footer5" @click="changeLang($t('language.language'))">
            <span>{{$t('language.language')}}</span>
            <template #icon="props">
                <img v-if="$t('language.language') === 'Chinese'" src="../../../assets/FooterLogo/cnlogo.png"/>
                <img v-else src="../../../assets/FooterLogo/enlogo.png"/>
            </template>
        </van-tabbar-item>
    </van-tabbar>
</template>
<script>
export default {
    data() {
        return {
            active: 'footer1',
            icon: {
                active: 'https://img01.yzcdn.cn/vant/user-active.png',
                inactive: 'https://img01.yzcdn.cn/vant/user-inactive.png',
            },
        };
    },
    methods:{
        LinkHome(){
            this.$router.push({name:'home'})
        },
        changeLang(v){
            if(v === 'Chinese'){
                this.$i18n.locale = 'zh',
                localStorage.setItem('languageSet',this.$i18n.locale)
            } else{
                this.$i18n.locale = 'en',
                localStorage.setItem('languageSet',this.$i18n.locale)
            }
        }
    }
};
</script>
