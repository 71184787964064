<template>
    <div>
        <div class="Company_header">
            <img class="Company_header"  src="../../assets/MobileImg/CompanyCNimg1.png" alt="banner1">
        </div>
        <div class="Company_center">
            <div v-if="$t('language.language') === 'Chinese'" class="ENMfontCente">
                <h1 class="Mcompany_titleBox">NORTHSTAR</h1>
                <div class="Mcompany_footBox">
                    Northstar trade co., limited is a full-service lab established in 2016 and it is located in Zhuhai China with around  80000 square feet. 
                    It is a modernization and high technology enterprise with 600 employees integrated administration, customer service, traditional 
                    and digital productions.
                    Our lab is proud to offer virtually every Crown&Bridge, Implants, Flexible& Acrylic Partials, Full Dentures and Orthodontic Appliances. 
                    Our lab strives to do business with this simple thought in mind: to add value to our dental partner. 
                    We achieve this goal by providing the consistent quality and reliable service. In the past 9 years, 
                    our lab has built more than 20 loyal customers around the USA and European through the commitment to
                     creating a high quality products at competitive prices.
                </div>
            </div>
             <div v-else class="CNMfontCente">
                 <h1 class="Mcompany_titleBox">NORTHSTAR</h1>
                <div class="Mcompany_footBox">
                    <p>領行齒科(香港)有限公司成立於2016年</p>
                    <p>是一家專注歐美國家中高端客戶的企業，個性化定制固定義齒。活動義齒與正畸類的數字化產品的企業</p>
                    <p>位於中國珠海高科技工業園，面積80000余平方英尺，可容納800人的高科技企業</p>
                    <p>目前公司人員規模500余人，集辦公、生產、數字化製造為一體的現代化高科技企業，</p>
                    <p>我們通過穩定的品質和可靠的服務而實現，9年創造，立足美國和歐洲，擁有了20多個客戶，每年服務600000萬需求者。</p>
                </div>
            </div>
            <!-- <img class="Company_center" v-if="$t('language.language') === 'Chinese'" src="../../assets/MobileImg/CompanyENimg2.jpg" alt="banner1">
            <img class="Company_center" v-else src="../../assets/MobileImg/CompanyCNimg2.jpg" alt="banner1"> -->
        </div>
        <div class="Company_footer">
            <img class="Company_footer1" v-if="$t('language.language') === 'Chinese'" src="../../assets/MobileImg/CompanyENimg3.png" alt="banner1">
            <img class="Company_footer2" v-else src="../../assets/MobileImg/CompanyCNimg3.jpg" alt="banner1">
        </div>
        <el-carousel :interval="2000" type="card"
                 height="140px" 
                 ref="carousel" 
                 :loop="true"
                 :initial-index="oIndex" 
                 indicator-position="none" 
                 class="Company_lunboBox"
                 @change="handleChange">
            <el-carousel-item ><img class="Company_lunboImg" src="../../assets/MobileImg/Companylunbo1.png"/></el-carousel-item>
            <el-carousel-item ><img class="Company_lunboImg" src="../../assets/MobileImg/Companylunbo2.jpg"/></el-carousel-item>
            <el-carousel-item ><img class="Company_lunboImg" src="../../assets/MobileImg/Companylunbo3.jpg"/></el-carousel-item>
        </el-carousel>
    </div>
</template>
<script>

export default {
    name:'CompanyPage',

    data(){
        return{
            oIndex:0,
        }
    },
    methods:{
        handleChange(index) {
            //参数index为图片索引
            this.oIndex = index;
            this.$refs.carousel.setActiveItem(index) //调动setActiveItem方法，动态切换图片
        },
    },
}
</script>
<style lang="less">
 .Company_header{
    width: 100%;
    height: 7rem;
}
.Company_center{
    width: 100%;
    // height:31rem;
}
.Company_footer1{
    margin-top: 1rem;
    width: 100%;
    height:6.9rem;
}
.Company_footer2{
    width: 100%;
    height:4.7rem;
}
.Company_lunboImg{
    width: 200px;
    height:140px;
}
.Company_lunboBox{
    .el-carousel__container{
        .el-carousel__arrow{
             display: none !important;
        }
    }
}
.Mcompany_titleBox{
    font-size:1.3rem;
    color: #000000;
}
.Mcompany_footBox{
    font-size: 0.5rem;
    padding:0 1rem;
}
</style>


