<template>
    <div>
        <van-nav-bar title="详情">
            <template #left>
                <van-icon name="arrow-left" size="18" color="#000" @click="backpage"/>
            </template>
        </van-nav-bar>
        <div v-if="$t('language.language') === 'Chinese'"  class="DetCenterBox" v-html="productDetDataEN[this.CurrtValue]"></div>
        <div v-else class="DetCenterBox" v-html="ProductDetObj[this.CurrtValue]"></div>
        <Footer/>
    </div>
</template>
<script>
import Footer from '../../components/MobileComponents/Footer/index';
import { ProductDetObj } from './productDetData.js';
import { productDetDataEN } from './productDetDataEN.js';
export default {
    components:{Footer},
    name:'productDetPage',
    data(){
        return{
            cuurtype:this.$route.query.active,
            CurrtValue:this.$route.query.value,
            ProductDetObj,
            productDetDataEN
        }
    },
     mounted(){
        // console.log(productDetDataEN)
    },
    methods:{
        backpage(){
            // this.$router.back();
            this.$router.push({name:this.$route.query.type,query:{active:this.cuurtype}}) 
        },
    }
}
</script>
<style>
.DetCenterBox{
    width: 100%;
    box-sizing: border-box;
    padding:0 15px;
}
.DetTitle{
    font-weight:bold;
    font-size: 1.2rem;
}
.DetCenterFoot{
    margin:8px 0;
    font-size: 1rem;
    text-align: left;
}
.DetimgBox{
    width: 100%;
    height:12.5rem;
}
</style>


