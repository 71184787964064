export const ProductDetObj = {
    productTypeCNimg1_1:
               "<p class='PcDetCenterFoot'>【產品名稱】</P>"+
               "<p class='PcDetCenterFoot'>威兰德全瓷系統/WIELAND</p>"+
               "<p class='PcDetCenterFoot'>【適用範圍】</p>"+
               "<p class='PcDetCenterFoot'>1、 單冠；長橋（允許長度的橋體，一般可以有顆牙）</p>"+
               "<p class='PcDetCenterFoot'>2、 單嵌體，也可做嵌體橋、全鋯冠、橋</p>"+
               "<p class='PcDetCenterFoot'>【產品特點】</P>"+
               "<p class='PcDetCenterFoot'>1、彎曲強度高達1400MPa</P>"+
               "<p class='PcDetCenterFoot'>2、高純度的原材料具有極佳通透性，採用液態等靜壓專利技術加工具有安全、可靠、穩定的生物相容性；同時採用內冠塗刷技術，滿足不同內冠顏色需要。</P>"+
               "<p class='PcDetCenterFoot'>【產品標準】</P>"+
               "<p class='PcDetCenterFoot'>牙冠材質：氧化鋯 厚度牙冠強度：0.7MM   牙冠強度：1440MP</P>"+
               "<img class='PcDetimgBox' src="+require('../../assets/MobileImg/ProductDetImgFile/productgrup1/1/detimg1.png')+">",
   productTypeCNimg1_2:
       "<p class='PcDetCenterFoot'>【產品名稱】</P>"+
       "<p class='PcDetCenterFoot'>Ablaze瓷系統</p>"+
       "<p class='PcDetCenterFoot'>【適用範圍】</p>"+
       "<p class='PcDetCenterFoot'>1、 單冠；長橋（允許48mm長度的橋體，一般可以有6顆牙）</p>"+
       "<p class='PcDetCenterFoot'>2、 單嵌體，也可做嵌體橋、全鋯冠、橋</p>"+
       "<p class='PcDetCenterFoot'>【產品特點】</P>"+
       "<p class='PcDetCenterFoot'>1、以超高強度、完美的邊緣密合度以及無以倫比的美學效果成為業界的奇跡 , 使全球範圍的患者受益。</P>"+
       "<p class='PcDetCenterFoot'>2、8類內冠顏色，專利染色技術，顏色完美呈現。</P>"+
                           "<p class='PcDetCenterFoot'>3、1220MPA高強度，完美通透性。</P>"+
                           "<p class='PcDetCenterFoot'>4、前牙內冠最薄至0.3MM，配套精密切削，精密度高。</P>"+
       "<p class='PcDetCenterFoot'>【產品標準】</P>"+
       "<p class='PcDetCenterFoot'>牙冠材質：氧化鋯 牙冠厚度：0.4-0.7MM  牙冠強度：1440MP。</P>"+
                           "<p class='PcDetCenterFoot'>【臨床建議】</P>"+
                           "<p class='PcDetCenterFoot'>1、全瓷牙正常製備標準；</P>"+
                           "<p class='PcDetCenterFoot'>2、取模邊緣線清晰可見；</P>"+
                           "<p class='PcDetCenterFoot'>3、建議用樹脂類粘接劑粘接；</P>"+
                       "<p class='PcDetCenterFoot'>4、如臨床需要對修復體調磨，請用精細金剛砂車針實行點磨，並在充分水冷下進行，最後須拋光上釉處理。</P>"+
                       "<img class='PcDetimgBox' src="+require('../../assets/MobileImg/ProductDetImgFile/productgrup1/2/detimg2.png')+">",
   productTypeCNimg1_3:
       "<p class='PcDetCenterFoot'>【產品名稱】</P>"+
       "<p class='PcDetCenterFoot'>威兰德全瓷系統/ WIELAND</p>"+
       "<p class='PcDetCenterFoot'>【適用範圍】</p>"+
       "<p class='PcDetCenterFoot'>1、 單冠；長橋（允許48mm長度的橋體，一般可以有6顆牙）</p>"+
       "<p class='PcDetCenterFoot'>2、 單嵌體，也可做嵌體橋、全鋯冠、橋</p>"+
       "<p class='PcDetCenterFoot'>【產品特點】</P>"+
       "<p class='PcDetCenterFoot'>1、彎曲強度高達1400MPa</P>"+
       "<p class='PcDetCenterFoot'>2、高純度的原材料具有極佳通透性，採用液態等靜壓專利技術加工具有安全、可靠、穩定的生物相容性；同時採用內冠塗刷技術，滿足不同內冠顏色需要。</P>"+
       "<p class='PcDetCenterFoot'>【產品標準】</P>"+
       "<p class='PcDetCenterFoot'>牙冠材質：氧化鋯 牙冠厚度：0.4-0.7MM  牙冠強度：1440MP。</P>"+
       "<img class='PcDetimgBox' src="+require('../../assets/MobileImg/ProductDetImgFile/productgrup1/3/detimg3.png')+">",
   productTypeCNimg1_4:
       "<p class='PcDetCenterFoot'>【產品名稱】</P>"+
       "<p class='PcDetCenterFoot'>Ablaze全瓷系統</p>"+
       "<p class='PcDetCenterFoot'>【適用範圍】</p>"+
       "<p class='PcDetCenterFoot'>1、 單冠；長橋（允許48mm長度的橋體，一般可以有6顆牙）</p>"+
       "<p class='PcDetCenterFoot'>2、 單嵌體，也可做嵌體橋、全鋯冠、橋</p>"+
       "<p class='PcDetCenterFoot'>【產品特點】</P>"+
       "<p class='PcDetCenterFoot'>1、以超高強度、完美的邊緣密合度以及無以倫比的美學效果成為業界的奇跡 , 使全球範圍的患者受益。</P>"+
       "<p class='PcDetCenterFoot'>2、8類內冠顏色，專利染色技術，顏色完美呈現。</P>"+
                           "<p class='PcDetCenterFoot'>3、1220MPA高強度，完美通透性。</P>"+
                           "<p class='PcDetCenterFoot'>4、前牙內冠最薄至0.3MM，配套精密切削，精密度高。</P>"+
       "<p class='PcDetCenterFoot'>【產品標準】</P>"+
       "<p class='PcDetCenterFoot'>牙冠材質：氧化鋯 牙冠厚度：0.4-0.7MM  牙冠強度：1440MP。</P>"+
                           "<p class='PcDetCenterFoot'>【臨床建議】</P>"+
                           "<p class='PcDetCenterFoot'>1、全瓷牙正常製備標準；</P>"+
                           "<p class='PcDetCenterFoot'>2、取模邊緣線清晰可見；</P>"+
                           "<p class='PcDetCenterFoot'>3、建議用樹脂類粘接劑粘接；</P>"+
                           "<p class='PcDetCenterFoot'>4、如臨床需要對修復體調磨，請用精細金剛砂車針實行點磨，並在充分水冷下進行，最後須拋光上釉處理。</P>"+
                           "<img class='PcDetimgBox' src="+require('../../assets/MobileImg/ProductDetImgFile/productgrup1/4/detimg4.png')+">",
   productTypeCNimg1_5:
       "<p class='PcDetCenterFoot'>【產品名稱】</P>"+
       "<p class='PcDetCenterFoot'>易美全瓷系統 / E.max Full Ceramic</p>"+
       "<p class='PcDetCenterFoot'>【適用範圍】</p>"+
       "<p class='PcDetCenterFoot'>1、單冠；3顆以內橋體，範圍局限於小臼齒，缺失位只有一顆，不可作懸臂式牙橋；</p>"+
       "<p class='PcDetCenterFoot'>2、貼面：傳統厚度：0.6mm  超薄貼面最薄可至0.2 mm 嵌體</p>"+
       "<p class='PcDetCenterFoot'>【產品特點】</P>"+
       "<p class='PcDetCenterFoot'>* 玻璃陶瓷類底冠材料</P>"+
       "<p class='PcDetCenterFoot'>* 高度的美學及光學效果，美學修復首選</P>"+
                           "<p class='PcDetCenterFoot'>* 粘接效果特佳，對人體無任何負作用，與自然牙完美融合</P>"+
       "<p class='PcDetCenterFoot'>【產品標準】</P>"+
       "<p class='PcDetCenterFoot'>牙冠材質：氧化鋯 牙冠厚度：0.4-0.7MM  牙冠強度：1220MP。</P>"+
       "<img class='PcDetimgBox' src="+require('../../assets/MobileImg/ProductDetImgFile/productgrup1/5/detimg5_1.png')+">"+
       "<img class='PcDetimgBox' src="+require('../../assets/MobileImg/ProductDetImgFile/productgrup1/5/detimg5_2.png')+">",
   productTypeCNimg1_6:
       "<p class='PcDetCenterFoot'>【產品名稱】</P>"+
       "<p class='PcDetCenterFoot'>LAVA全瓷系统 / Lava Full Ceramic</p>"+
       "<p class='PcDetCenterFoot'>【適用範圍】</p>"+
       "<p class='PcDetCenterFoot'>1、單冠；長橋（允許48mm長度的橋體，一般可以有6顆牙）</p>"+
       "<p class='PcDetCenterFoot'>2、單嵌體，也可做嵌體橋、全鋯冠、橋</p>"+
       "<p class='PcDetCenterFoot'>【產品特點】</P>"+
       "<p class='PcDetCenterFoot'>1、以超高強度、完美的邊緣密合度以及無以倫比的美學效果成為業界的奇跡 , 使全球範圍的患者受益。</P>"+
       "<p class='PcDetCenterFoot'>2、8類內冠顏色，專利染色技術，顏色完美呈現。</P>"+
                           "<p class='PcDetCenterFoot'>3、1440MPA高強度，完美通透性。</P>"+
                           "<p class='PcDetCenterFoot'>4、前牙內冠最薄至0.3MM，配套精密切削，精密度高。</P>"+
       "<p class='PcDetCenterFoot'>【產品標準】</P>"+
       "<p class='PcDetCenterFoot'>牙冠材質：氧化鋯 牙冠厚度：0.4-0.7MM  牙冠強度：1440MP。</P>"+
       "<img class='PcDetimgBox' src="+require('../../assets/MobileImg/ProductDetImgFile/productgrup1/6/detimg6_1.png')+">"+
       "<img class='PcDetimgBox' src="+require('../../assets/MobileImg/ProductDetImgFile/productgrup1/6/detimg6_2.png')+">",
   productTypeCNimg2_1:
           "<p class='PcDetCenterFoot'>【產品名稱】</P>"+
           "<p class='PcDetCenterFoot'>生物鐳射烤瓷</p>"+
           "<p class='PcDetCenterFoot'>【品牌介紹】</p>"+
           "<p class='PcDetCenterFoot'>採用選擇性鐳射熔化，是一項尖端的電腦（CAD/CAM技術）技術通過CAM電腦控制鐳射，選擇性層層堆積熔化金屬粉末，直接形成金屬底冠，再加烘烤瓷而成。</p>"+
           "<p class='PcDetCenterFoot'>【產品特點】</P>"+
           "<p class='PcDetCenterFoot'>1、不是傳統的金屬鑄造技術製作內冠。</P>"+
                               "<p class='PcDetCenterFoot'>2、精確度極高，底冠光潔緻密，更利於口腔安全健康。</P>"+
                               "<p class='PcDetCenterFoot'>【產品標準】</P>"+
           "<p class='PcDetCenterFoot'>1、鈷鉻合金內冠 + 烤瓷層；</P>"+
                               "<p class='PcDetCenterFoot'>2、金屬內殼厚度 0.3MM；</P>"+
                               "<p class='PcDetCenterFoot'>3、烤瓷冠唇頰側邊緣烤瓷飾面，舌側露 2-3MM寬微細金屬邊；</P>"+
                               "<p class='PcDetCenterFoot'>4、也可選擇全包瓷、全金屬腳邊或瓷肩臺設計。</P>"+
          "<img class='PcDetimgBox' src="+require('../../assets/MobileImg/ProductDetImgFile/productgrup2/1/grupimg1_1.png')+">"+
          "<img class='PcDetimgBox' src="+require('../../assets/MobileImg/ProductDetImgFile/productgrup2/1/grupimg1_2.png')+">",
   productTypeCNimg2_2:
               "<p class='PcDetCenterFoot'>【產品名稱】</P>"+
               "<p class='PcDetCenterFoot'>金屬嵌體 / METAL INLAY</p>"+
               "<p class='PcDetCenterFoot'>【適用範圍】</p>"+
               "<p class='PcDetCenterFoot'>適用於牙體雖缺損，但尚有較大體積的健康牙體餘留的修復。</p>"+
               "<p class='PcDetCenterFoot'>【產品特點】</P>"+
               "<p class='PcDetCenterFoot'>1、較口內充填而言，嵌體在形態和功能上恢復更理想，且高度拋光，不易附著菌斑，容易清潔，強度和耐久性也更好。</P>"+
                                   "<p class='PcDetCenterFoot'>2、金合金嵌體化學性能穩定，有良好的延展性和機械性能，是製作後牙嵌體理想的傳統修復材料。</P>"+
                                   "<p class='PcDetCenterFoot'>3、但美觀性較差。</P>"+
                                   "<p class='PcDetCenterFoot'>【產品標準】</P>"+
               "<p class='PcDetCenterFoot'>1、全金屬嵌體；</P>"+
                                   "<p class='PcDetCenterFoot'>2、可選擇鎳鉻合金、鈷鉻合金、純鈦及各種貴金屬。</P>"+
               "<img class='PcDetimgBox' src="+require('../../assets/MobileImg/ProductDetImgFile/productgrup2/2/grupimg2_3.png')+">"+
               "<img class='PcDetimgBox' src="+require('../../assets/MobileImg/ProductDetImgFile/productgrup2/2/grupimg2_4.png')+">",
   productTypeCNimg2_3:
               "<p class='PcDetCenterFoot'>【產品名稱】</P>"+
               "<p class='PcDetCenterFoot'>金屬嵌體 / METAL INLAY</p>"+
               "<p class='PcDetCenterFoot'>【適用範圍】</p>"+
               "<p class='PcDetCenterFoot'>適用於牙體雖缺損，但尚有較大體積的健康牙體餘留的修復。</p>"+
               "<p class='PcDetCenterFoot'>【產品特點】</P>"+
               "<p class='PcDetCenterFoot'>1、較口內充填而言，嵌體在形態和功能上恢復更理想，且高度拋光，不易附著菌斑，容易清潔，強度和耐久性也更好。</P>"+
                                   "<p class='PcDetCenterFoot'>2、金合金嵌體化學性能穩定，有良好的延展性和機械性能，是製作後牙嵌體理想的傳統修復材料。</P>"+
                                   "<p class='PcDetCenterFoot'>3、但美觀性較差。</P>"+
                                   "<p class='PcDetCenterFoot'>【產品標準】</P>"+
               "<p class='PcDetCenterFoot'>1、全金屬嵌體；</P>"+
                                   "<p class='PcDetCenterFoot'>2、可選擇鎳鉻合金、鈷鉻合金、純鈦及各種貴金屬。</P>"+
               "<img class='PcDetimgBox' src="+require('../../assets/MobileImg/ProductDetImgFile/productgrup2/3/grupimg2_5.png')+">"+
               "<img class='PcDetimgBox' src="+require('../../assets/MobileImg/ProductDetImgFile/productgrup2/3/grupimg2_6.png')+">",
   productTypeCNimg2_4:
               "<p class='PcDetCenterFoot'>【產品名稱】</P>"+
               "<p class='PcDetCenterFoot'>金屬烤瓷冠/ PORCELAIN FUSED METAL CROWN</p>"+
               "<p class='PcDetCenterFoot'>【產品特點】</P>"+
               "<p class='PcDetCenterFoot'>1、傳統的金屬鑄造技術製作內冠。</P>"+
                                   "<p class='PcDetCenterFoot'>2、精確度極高，底冠光潔緻密，更利於口腔安全健康。</P>"+
                                   "<p class='PcDetCenterFoot'>【產品標準】</P>"+
               "<p class='PcDetCenterFoot'>1、金屬內冠 + 烤瓷層燒結而成；</P>"+
                                   "<p class='PcDetCenterFoot'>2、烤瓷冠唇頰側邊緣烤瓷飾面，舌側露 2-3MM寬微細金屬邊；</P>"+
                                   "<p class='PcDetCenterFoot'>3、可選擇普通鈷鉻合金、貴金屬、純鈦等金屬內冠材料；</P>"+
                                   "<p class='PcDetCenterFoot'>4、也可選擇全包瓷、全金屬邊緣或瓷肩臺冠設計。</P>"+
               "<img class='PcDetimgBox' src="+require('../../assets/MobileImg/ProductDetImgFile/productgrup2/4/grupimg2_7.png')+">"+
               "<img class='PcDetimgBox' src="+require('../../assets/MobileImg/ProductDetImgFile/productgrup2/4/grupimg2_8.png')+">",
   productTypeCNimg2_5:
           "<p class='PcDetCenterFoot'>【產品名稱】</P>"+
           "<p class='PcDetCenterFoot'>馬里蘭橋</p>"+
           "<p class='PcDetCenterFoot'>【品牌介紹】</p>"+
           "<p class='PcDetCenterFoot'>粘結橋（Resin bonded fixed partial dentures）是一種基本不磨或少磨健康鄰牙，用酸蝕—粘結技術修復個別牙缺失的固定修復體。粘結橋的發展是隨著粘結技術和修復材料的發展而發展的。</p>"+
           "<p class='PcDetCenterFoot'>【產品特點】</P>"+
           "<p class='PcDetCenterFoot'>1、馬里蘭橋修復幾乎不需要自然拔牙，是由兩個附著在假牙上的金屬薄片或HPP薄片組成，所以不會磨除缺失牙周圍的健康牙齒。(不像烤/全瓷牙要磨掉兩邊健康的牙齒)；</P>"+
           "<p class='PcDetCenterFoot'>2、馬里蘭橋這些薄片是使用粘合劑粘合在鄰近缺失牙齒區域的牙齒背面，不需要做手術，幾乎感覺不到疼痛；</P>"+
           "<p class='PcDetCenterFoot'>3、馬里蘭橋牙相比種植牙的價格也比較低；</P>"+
           "<p class='PcDetCenterFoot'>4、價格相對低、使用壽命長。</P>"+
           "<img class='PcDetimgBox' src="+require('../../assets/MobileImg/ProductDetImgFile/productgrup2/5/grupimg2_9.png')+">"+
           "<img class='PcDetimgBox' src="+require('../../assets/MobileImg/ProductDetImgFile/productgrup2/5/grupimg2_10.png')+">",
   productTypeCNimg2_6:
           "<p class='PcDetCenterFoot'>【產品名稱】</P>"+
           "<p class='PcDetCenterFoot'>馬里蘭橋</p>"+
           "<p class='PcDetCenterFoot'>【品牌介紹】</p>"+
           "<p class='PcDetCenterFoot'>粘結橋（Resin bonded fixed partial dentures）是一種基本不磨或少磨健康鄰牙，用酸蝕—粘結技術修復個別牙缺失的固定修復體。粘結橋的發展是隨著粘結技術和修復材料的發展而發展的。</p>"+
           "<p class='PcDetCenterFoot'>【產品特點】</P>"+
           "<p class='PcDetCenterFoot'>1、馬里蘭橋修復幾乎不需要自然拔牙，是由兩個附著在假牙上的金屬薄片或HPP薄片組成，所以不會磨除缺失牙周圍的健康牙齒。(不像烤/全瓷牙要磨掉兩邊健康的牙齒)；</P>"+
           "<p class='PcDetCenterFoot'>2、馬里蘭橋這些薄片是使用粘合劑粘合在鄰近缺失牙齒區域的牙齒背面，不需要做手術，幾乎感覺不到疼痛；</P>"+
           "<p class='PcDetCenterFoot'>3、馬里蘭橋牙相比種植牙的價格也比較低；</P>"+
           "<p class='PcDetCenterFoot'>4、價格相對低、使用壽命長。</P>"+
           "<img class='PcDetimgBox' src="+require('../../assets/MobileImg/ProductDetImgFile/productgrup2/6/grupimg2_11.png')+">"+
           "<img class='PcDetimgBox' src="+require('../../assets/MobileImg/ProductDetImgFile/productgrup2/6/grupimg2_12.png')+">",
   productTypeCNimg3_1:
           "<p class='PcDetCenterFoot'>【產品名稱】</P>"+
           "<p class='PcDetCenterFoot'>種植覆蓋義齒</p>"+
           "<p class='PcDetCenterFoot'>【適用範圍】</p>"+
           "<p class='PcDetCenterFoot'>無牙頜患者的修復治療</p>"+
           "<p class='PcDetCenterFoot'>【產品特點】</P>"+
           "<p class='PcDetCenterFoot'>1、更好的固位及穩定，因為有了種植體的輔助提供固位力，固位穩定效果更好；</P>"+
                               "<p class='PcDetCenterFoot'>2、更高的咀嚼效率，因為固位穩定效果好，吃飯不會鬆動，沒有塞飯的尷尬；</P>"+
                               "<p class='PcDetCenterFoot'>3、更高的舒適度以及滿意度，因為基托和邊緣可以做得更短，甚至沒有基托，口內異物感更小；</P>"+
                               "<p class='PcDetCenterFoot'>4、種植體的植入保留了其周圍的牙槽骨，減緩了牙槽骨的吸收。</P>"+
                               "<p class='PcDetCenterFoot'>【產品標準】</P>"+
           "<p class='PcDetCenterFoot'>1、至少兩個種植體提供固位，並有良好的共同就位道。</P>"+
           "<p class='PcDetCenterFoot'>2、可選擇杆卡式、球帽、磁性附著體以及雙套冠等方式固位元。</P>"+
           "<img class='PcDetimgBox' src="+require('../../assets/MobileImg/ProductDetImgFile/productgrup3/1/grupimg3_1.png')+">"+
           "<img class='PcDetimgBox' src="+require('../../assets/MobileImg/ProductDetImgFile/productgrup3/1/grupimg3_2.png')+">",
   productTypeCNimg3_2:
   "<p class='PcDetCenterFoot'>【產品名稱】</P>"+
   "<p class='PcDetCenterFoot'>種植導板</p>"+
   "<p class='PcDetCenterFoot'>【適用範圍】</p>"+
   "<p class='PcDetCenterFoot'>無牙頜患者的修復治療</p>"+
   "<p class='PcDetCenterFoot'>【種植解決方案】</P>"+
   "<p class='PcDetCenterFoot'>1、醫生提供CBCT資料以及口內資料，即可完成3D導板的設計，降低手術風險；</P>"+
                       "<p class='PcDetCenterFoot'>2、使用3D種植導板技術，在植體完成模擬植入的同時，系統會自動生成相對應的基臺模型資料庫，為後期製作個性化基臺提供基礎。</P>"+
                       "<p class='PcDetCenterFoot'>【產品特點】</P>"+
   "<p class='PcDetCenterFoot'>1、植體模擬植入效果與後期修復效果相結合，提高種植牙的整體修復效果</P>"+
   "<p class='PcDetCenterFoot'>2、手術器械的提供，可完成種植體的植入，提高整個手術的精確度；</P>"+
   "<p class='PcDetCenterFoot'>3、設計完成之後，可提供給醫生一個電子手術計畫，計畫中記錄了植體的基本資訊以及植體的牙槽骨內的所有情況，醫生確認完成之後即可進行3D導板加工；</P>"+
   "<p class='PcDetCenterFoot'>4、也可以給醫生提供設計的資料手術計畫，允許醫生更改植體位置資訊，大大改善了電子手術計畫在醫技溝通上的效率。</P>"+
   "<img class='PcDetimgBox' src="+require('../../assets/MobileImg/ProductDetImgFile/productgrup3/2/grupimg3_3.png')+">"+
   "<img class='PcDetimgBox' src="+require('../../assets/MobileImg/ProductDetImgFile/productgrup3/2/grupimg3_4.png')+">",
   productTypeCNimg3_3:
                       "<p class='PcDetCenterFoot'>【產品名稱】</P>"+
                       "<p class='PcDetCenterFoot'>種植个性化基台</p>"+
                       "<p class='PcDetCenterFoot'>【適用範圍】</p>"+
                       "<p class='PcDetCenterFoot'>無牙頜患者的修復治療</p>"+
                       "<p class='PcDetCenterFoot'>有258套种植系统选择，满足一切患者需求</p>"+
                       "<p class='PcDetCenterFoot'>【產品特點】</P>"+
                       "<p class='PcDetCenterFoot'>1、自然的解剖面和贴合度</P>"+
                                           "<p class='PcDetCenterFoot'>2、满足各种穿龈高度及超大角度的需求</P>"+
                                           "<p class='PcDetCenterFoot'>3、免除临床医生选择基台的种种烦恼</P>"+
                                           "<p class='PcDetCenterFoot'>4、让复杂的种植简单化、低成本、低风险</P>"+
                                           "<p class='PcDetCenterFoot'>【產品標準】</P>"+
                       "<p class='PcDetCenterFoot'>1、至少兩個種植體提供固位，並有良好的共同就位道。</P>"+
                       "<p class='PcDetCenterFoot'>2、可選擇杆卡式、球帽、磁性附著體以及雙套冠等方式固位元。</P>"+
                       "<img class='PcDetimgBox' src="+require('../../assets/MobileImg/ProductDetImgFile/productgrup3/3/grupimg3_5.png')+">"+
                       "<img class='PcDetimgBox' src="+require('../../assets/MobileImg/ProductDetImgFile/productgrup3/3/grupimg3_6.png')+">"+
                   " <img class='PcDetimgBox' src="+require('../../assets/MobileImg/ProductDetImgFile/productgrup3/3/grupimg3_7.png')+">",
   productTypeCNimg3_4:
   "<p class='PcDetCenterFoot'>【產品名稱】</P>"+
   "<p class='PcDetCenterFoot'>鑄造個性化種植支架</p>"+
   "<p class='PcDetCenterFoot'>【產品特點】</P>"+
   "<p class='PcDetCenterFoot'>1、個性化支架和成品基臺連體鑄造，在基牙或基牙代型上連接塑膠或蠟型，然後包埋去蠟鑄造打磨完成後，在支架上進行上部修復。支架通過螺絲與口內下部種植體連接；</P>"+
                       "<p class='PcDetCenterFoot'>2、金屬支架可起到增加上部結構的強度、固位元及分散合力等作用；</P>"+
                       "<p class='PcDetCenterFoot'>3、更理想的美觀和功能效果；</P>"+
                       "<p class='PcDetCenterFoot'>4、患者可以後期自己保養和維護。</P>"+
                       "<p class='PcDetCenterFoot'>【產品標準】</P>"+
   "<p class='PcDetCenterFoot'>1、個性化支架和成品基臺連體鑄造，在支架上鑄造出基牙或基牙代型等形狀，再在其上進行上部修復。醫生來上部成品基臺；</P>"+
   "<p class='PcDetCenterFoot'>2、配套的連接螺絲；</P>"+
   "<p class='PcDetCenterFoot'>3、支架材料根據成品基臺金屬類型選擇同等材料，如果基臺為塑膠可鑄件，則可選擇任一金屬；</P>"+
   "<p class='PcDetCenterFoot'>4、上部修復可選擇直接烤瓷或烤塑修復、冠修復或排人工牙修復。</P>"+
   "<p class='PcDetCenterFoot'>【工藝流程】</P>"+
   "<p class='PcDetCenterFoot'>設計支架→鑄造支架→臨床試戴支架→完成上部修復。</P>"+
   " <img class='PcDetimgBox' src="+require('../../assets/MobileImg/ProductDetImgFile/productgrup3/4/grupimg3_8.png')+">",
   productTypeCNimg3_5:
       "<p class='PcDetCenterFoot'>【產品名稱】</P>"+
       "<p class='PcDetCenterFoot'>鑄造個性化種植支架</p>"+
       "<p class='PcDetCenterFoot'>【產品特點】</P>"+
       "<p class='PcDetCenterFoot'>1、個性化支架和成品基臺連體鑄造，在基牙或基牙代型上連接塑膠或蠟型，然後包埋去蠟鑄造打磨完成後，在支架上進行上部修復。支架通過螺絲與口內下部種植體連接；</P>"+
                           "<p class='PcDetCenterFoot'>2、金屬支架可起到增加上部結構的強度、固位元及分散合力等作用；</P>"+
                           "<p class='PcDetCenterFoot'>3、更理想的美觀和功能效果；</P>"+
                           "<p class='PcDetCenterFoot'>4、患者可以後期自己保養和維護。</P>"+
                           "<p class='PcDetCenterFoot'>【產品標準】</P>"+
       "<p class='PcDetCenterFoot'>1、個性化支架和成品基臺連體鑄造，在支架上鑄造出基牙或基牙代型等形狀，再在其上進行上部修復。醫生來上部成品基臺；</P>"+
       "<p class='PcDetCenterFoot'>2、配套的連接螺絲；</P>"+
       "<p class='PcDetCenterFoot'>3、支架材料根據成品基臺金屬類型選擇同等材料，如果基臺為塑膠可鑄件，則可選擇任一金屬；</P>"+
       "<p class='PcDetCenterFoot'>4、上部修復可選擇直接烤瓷或烤塑修復、冠修復或排人工牙修復。</P>"+
       "<p class='PcDetCenterFoot'>【工藝流程】</P>"+
       "<p class='PcDetCenterFoot'>設計支架→鑄造支架→臨床試戴支架→完成上部修復。</P>"+
       " <img class='PcDetimgBox' src="+require('../../assets/MobileImg/ProductDetImgFile/productgrup3/5/grupimg3_9.png')+">",
   productTypeCNimg3_6:
   "<p class='PcDetCenterFoot'>【產品名稱】</P>"+
   "<p class='PcDetCenterFoot'>種植个性化基台</p>"+
   "<p class='PcDetCenterFoot'>【適用範圍】</p>"+
   "<p class='PcDetCenterFoot'>無牙頜患者的修復治療</p>"+
   "<p class='PcDetCenterFoot'>有258套种植系统选择，满足一切患者需求</p>"+
   "<p class='PcDetCenterFoot'>【產品特點】</P>"+
   "<p class='PcDetCenterFoot'>1、自然的解剖面和贴合度</P>"+
                       "<p class='PcDetCenterFoot'>2、满足各种穿龈高度及超大角度的需求</P>"+
                       "<p class='PcDetCenterFoot'>3、免除临床医生选择基台的种种烦恼</P>"+
                       "<p class='PcDetCenterFoot'>4、让复杂的种植简单化、低成本、低风险</P>"+
                       "<p class='PcDetCenterFoot'>【產品標準】</P>"+
   "<p class='PcDetCenterFoot'>1、至少兩個種植體提供固位，並有良好的共同就位道。</P>"+
                       "<p class='PcDetCenterFoot'>2、可選擇杆卡式、球帽、磁性附著體以及雙套冠等方式固位元。</P>"+
                       " <img class='PcDetimgBox' src="+require('../../assets/MobileImg/ProductDetImgFile/productgrup3/6/grupimg3_10.png')+">"+
                       " <img class='PcDetimgBox' src="+require('../../assets/MobileImg/ProductDetImgFile/productgrup3/6/grupimg3_11.png')+">"+
                       " <img class='PcDetimgBox' src="+require('../../assets/MobileImg/ProductDetImgFile/productgrup3/6/grupimg3_12.png')+">",
   productTypeCNimg4_1:
   "<p class='PcDetCenterFoot'>【產品名稱】</P>"+
   "<p class='PcDetCenterFoot'>护磨牙墊  Night guards 有以下三種類型可選擇：</p>"+
   "<p class='PcDetCenterFoot'>1、軟硬合拼防磨牙墊   Night guard  ；</p>"+
   "<p class='PcDetCenterFoot'>2、咬合軟膠/防磨牙墊 Night guard (pressure-formed)；</p>"+
   "<p class='PcDetCenterFoot'>3、咬合軟膠/防磨牙墊 Night guard (pressure-formed)。</p>"+
   "<p class='PcDetCenterFoot'>【適用範圍】</p>"+
   "<p class='PcDetCenterFoot'>防夜磨牙，睡覺時戴用。咬合軟膠適用於防輕度夜磨牙。</p>"+
   "<p class='PcDetCenterFoot'>【產品特點】</P>"+
   "<p class='PcDetCenterFoot'>1、咬合較膠：軟膠，戴用舒適，但不耐磨。彩色膠片，外觀漂亮。</P>"+
                       "<p class='PcDetCenterFoot'>2、咬合硬膠：硬膠，戴用舒適感差一些，但耐磨。</P>"+
                       "<p class='PcDetCenterFoot'>3、軟硬合拼：採用裡軟外硬的特殊透明膠片，即保證戴用的舒適度，又耐磨。</P>"+
                       "<p class='PcDetCenterFoot'>【產品標準】</P>"+
   "<p class='PcDetCenterFoot'>1、咬合較膠：採用2-3MM單色彩膠片或三色混合彩膠片, 用熱壓塑技術, 軟膠覆蓋到牙齦；</P>"+
                       "<p class='PcDetCenterFoot'>2、咬合硬膠：採用2-3MM透明色硬膠片,用熱壓塑技術製作，也可用熱凝技術製作。硬膠墊唇頰面覆蓋部分牙冠，舌面覆蓋到牙齦；</P>"+
                       "<p class='PcDetCenterFoot'>2、軟硬合拼：採用2-3MM厚一面是軟膠一面是硬膠的特殊透明膠片，用熱壓塑技術, 膠基</P>"+
                       "<p class='PcDetCenterFoot'>【臨床建議】</P>"+
                       "<p class='PcDetCenterFoot'>1、需要對頜模型和咬合關係蠟堤；</P>"+
                       "<p class='PcDetCenterFoot'>2、防磨牙，睡覺前戴上。舒適，耐磨。</P>"+
                       "<img class='PcDetimgBox' src="+require('../../assets/MobileImg/ProductDetImgFile/productgrup4/1/grupimg4_1.png')+">"+
                       "<img class='PcDetimgBox' src="+require('../../assets/MobileImg/ProductDetImgFile/productgrup4/1/grupimg4_2.png')+">",
   productTypeCNimg4_2:
   "<p class='PcDetCenterFoot'>【產品名稱】</P>"+
   "<p class='PcDetCenterFoot'>防鼾器 Anti-snoring Device</p>"+
   "<p class='PcDetCenterFoot'>【適用範圍】</p>"+
   "<p class='PcDetCenterFoot'>防打鼾用。戴上防鼾器後能將下頜往前推，擴開睡眠時縮小的呼吸氣道，呼吸時產生的共鳴鼾聲便會降低或去除。</p>"+
   "<p class='PcDetCenterFoot'>【產品標準】</P>"+
   "<p class='PcDetCenterFoot'>1、採用透明色硬膠片或軟膠片，用熱壓塑技術製作。</P>"+
                       "<p class='PcDetCenterFoot'>2、1套2件進口可調杆, 可調杆固定在上下託盤的左側和右側。</P>"+
                       "<p class='PcDetCenterFoot'>【臨床建議】</P>"+
   "<p class='PcDetCenterFoot'>1、防鼾器在加工初期, 上下頜在正中合的基礎上下頜前移2mm左右固定可調杆,醫生按臨床需要更換可調杆長度,可調杆越短,下頜越往前,可調杆越長,下頜越往後，睡眠前戴上</P>"+
   "<img class='PcDetimgBox' src="+require('../../assets/MobileImg/ProductDetImgFile/productgrup4/2/grupimg4_3.png')+">"+
   "<img class='PcDetimgBox' src="+require('../../assets/MobileImg/ProductDetImgFile/productgrup4/2/grupimg4_4.png')+">",
   productTypeCNimg4_3:
   "<p class='PcDetCenterFoot'>【產品名稱】</P>"+
   "<p class='PcDetCenterFoot'>法蘭克爾矯正器  Frankel Appliance</p>"+
   "<p class='PcDetCenterFoot'>【適用範圍】</p>"+
   "<p class='PcDetCenterFoot'>1、 I 型矯正安氏I 類和II類1分類錯合的功能矯正器；</p>"+
   "<p class='PcDetCenterFoot'>2、 II 型矯正安氏II類2分類錯合的功能矯正器；</p>"+
   "<p class='PcDetCenterFoot'>3、 III型矯正安氏III類錯合的功能矯正器；</p>"+
   "<p class='PcDetCenterFoot'>4、 Ⅳ型主要用於替牙期和恒牙早期牙弓狹窄，基骨發育不足的雙頜前突及輕度骨性開合畸形。</p>"+
   "<p class='PcDetCenterFoot'>【產品標準】</P>"+
   "<p class='PcDetCenterFoot'>唇擋+頰屏+唇擋絲+唇弓+齶弓+合支托。</P>"+
                       "<p class='PcDetCenterFoot'>【臨床建議】</P>"+
                       "<p class='PcDetCenterFoot'>1、II類醫生按下頜前伸不超過2.5-3MM，若覆蓋4MM左右下頜可前伸至切對切的關係取合記錄，前牙垂直打開一般約為2.5MM。如覆蓋過大，可逐步前伸；III類一般前牙呈切對切的關係，反覆合者，打開2-3MM。</P>"+
                       "<p class='PcDetCenterFoot'>2、印模必須準確反應口腔內軟硬組織的形態，印模包括全牙列、牙槽突、粘膜皺襞的整個前庭區、唇頰舌系帶以及上頜結節。</P>"+
                       "<p class='PcDetCenterFoot'>3、患者初戴時每天僅戴用1-3小時，夜間可暫不使用，無不適即可逐步增加；適應後應全天戴用，每天不少於14個小時，矯治器不用時，須放入塑膠或金屬盒中存放，以免丟失或擠壓變形。</P>"+
                       "<img class='PcDetimgBox' src="+require('../../assets/MobileImg/ProductDetImgFile/productgrup4/3/grupimg4_5.png')+">"+
   "<img class='PcDetimgBox' src="+require('../../assets/MobileImg/ProductDetImgFile/productgrup4/3/grupimg4_6.png')+">",
   productTypeCNimg4_4:
   "<p class='PcDetCenterFoot'>【產品名稱】</P>"+
   "<p class='PcDetCenterFoot'>哈利氏保持器   Hawley retainer </p>"+
   "<p class='PcDetCenterFoot'>【適用範圍】</P>"+
   "<p class='PcDetCenterFoot'>為保持臨床矯治效果，矯治結束後佩戴，可以使牙小量移動，或通過調節唇弓關閉前牙少量間隙。</P>"+
                       "<p class='PcDetCenterFoot'>【產品特點】</P>"+
                       "<p class='PcDetCenterFoot'>是一種常用的標準活動矯治器，患者可自行摘戴。</P>"+
                       "<p class='PcDetCenterFoot'>【產品標準】</P>"+
                       "<p class='PcDetCenterFoot'>由1個唇弓+ 2個箭頭卡環 + 1塊淨色樹脂膠基托板。</P>"+
   "<p class='PcDetCenterFoot'>【臨床建議】</P>"+
                       "<p class='PcDetCenterFoot'>1、哈利氏保持器除刷牙外可長時間佩戴，為穩定和保持效果，應持續佩戴較長的一段時間；</P>"+
                       "<p class='PcDetCenterFoot'>2、如想加強保持效果，可將唇弓貼牙做；</P>"+
                       "<p class='PcDetCenterFoot'>3、具體應用請醫師根據臨床情況而定，此建議僅供參考。</P>"+
                       "<img class='PcDetimgBox' src="+require('../../assets/MobileImg/ProductDetImgFile/productgrup4/4/grupimg4_7.png')+">"+
                       "<img class='PcDetimgBox' src="+require('../../assets/MobileImg/ProductDetImgFile/productgrup4/4/grupimg4_8.png')+">",
   productTypeCNimg4_5:
   "<p class='PcDetCenterFoot'>【產品名稱】</P>"+
   "<p class='PcDetCenterFoot'>運動護齒套 Sport Guards</p>"+
   "<p class='PcDetCenterFoot'>【適用範圍】</P>"+
   "<p class='PcDetCenterFoot'>1、基本型適用於排球、自行車等運動的口腔保護；</P>"+
                       "<p class='PcDetCenterFoot'>2、衝撞型適用於足球、籃球、壘球、溜冰、輪滑等運動的口腔保護；</P>"+
                       "<p class='PcDetCenterFoot'>3、專業抗擊型適用於拳擊、曲棍球等運動的口腔保護。</P>"+
                       "<p class='PcDetCenterFoot'>【產品標準】</P>"+
   "<p class='PcDetCenterFoot'>1、基本型採用3MM+4MM或者2MM+5MM厚的兩層透明膠片，用熱壓塑技術製作，完成後的厚度約為3MM。膠片唇舌側均覆蓋到牙齦。</P>"+
                       "<p class='PcDetCenterFoot'>2、衝撞型採用3MM+4MM或者2MM+5MM厚的兩層透明膠片，用熱壓塑技術製作，完成後的厚度約為5MM。膠片唇舌側均覆蓋到牙齦。</P>"+
                       "<p class='PcDetCenterFoot'>3、專業抗擊型採用一片約7MM厚的特殊三層透明膠片，前牙區膠片中間層為硬質層，透明色，後牙區無此硬質層，是單色的彩色膠片，用熱壓塑技術製作，完成後的厚度約為5MM。膠片唇舌側均覆蓋到牙齦。</P>"+
                       "<p class='PcDetCenterFoot'>【臨床建議】</P>"+
                       "<p class='PcDetCenterFoot'>1、 製作需要對頜模型和咬合關係蠟堤；</P>"+
                       "<p class='PcDetCenterFoot'>2、 一般只做上頜，膠片止於兩側第一磨牙。</P>"+
                       "<img class='PcDetimgBox' src="+require('../../assets/MobileImg/ProductDetImgFile/productgrup4/5/grupimg4_9.png')+">"+
                       "<img class='PcDetimgBox' src="+require('../../assets/MobileImg/ProductDetImgFile/productgrup4/5/grupimg4_10.png')+">",
   productTypeCNimg4_6:
   "<p class='PcDetCenterFoot'>【產品名稱】</P>"+
   "<p class='PcDetCenterFoot'>螺旋矯正器 Expansion screw Appliance (3-direction Screw)</p>"+
   "<p class='PcDetCenterFoot'>【適用範圍】</p>"+
   "<p class='PcDetCenterFoot'>牙弓擁擠時, 擴張牙弓。</p>"+
   "<p class='PcDetCenterFoot'>有258套种植系统选择，满足一切患者需求</p>"+
   "<p class='PcDetCenterFoot'>【產品特點】</P>"+
   "<p class='PcDetCenterFoot'>1、主要是通過螺旋器加力擴弓；</P>"+
                       "<p class='PcDetCenterFoot'>2、活動矯治器，醫生或患者可自行摘戴。</P>"+
                       "<p class='PcDetCenterFoot'>【產品標準】</P>"+
   "<p class='PcDetCenterFoot'>2個箭頭卡環固位+1塊淨色樹脂膠基托板+1個3面推螺旋器。</P>"+
                       "<p class='PcDetCenterFoot'>2、可選擇杆卡式、球帽、磁性附著體以及雙套冠等方式固位元。</P>"+
                       "<p class='PcDetCenterFoot'>【臨床建議】</P>"+
                       "<p class='PcDetCenterFoot'>1、加力時，應逐步加力。</P>"+
                       "<p class='PcDetCenterFoot'>2、牙弓擴開後，再用其它矯正器排列牙齒。</P>"+
                       "<p class='PcDetCenterFoot'>3、除2個箭頭卡、基托、1個3面推螺旋器外，如設計其它附件需另外加收費用。</P>"+
                       "<p class='PcDetCenterFoot'>4、具體應用請醫師根據臨床情況而定，此建議僅供參考。</P>"+
                       "<img class='PcDetimgBox' src="+require('../../assets/MobileImg/ProductDetImgFile/productgrup4/6/grupimg4_11.png')+">",
   productTypeCNimg5_1:
   "<p class='PcDetCenterFoot'>【產品名稱】</P>"+
   "<p class='PcDetCenterFoot'>德國BPD完美支架 / Bego Perfect Denture</p>"+
   "<p class='PcDetCenterFoot'>【品牌介紹】</p>"+
   "<p class='PcDetCenterFoot'>德國百年牙科品牌BEGO公司的金屬支架材料。</p>"+
   "<p class='PcDetCenterFoot'>【適用範圍】</p>"+
   "<p class='PcDetCenterFoot'>牙列缺損的活動修復用支架。</p>"+
   "<p class='PcDetCenterFoot'>【產品特點】</P>"+
   "<p class='PcDetCenterFoot'>支架具有優越的彈性、密合、超薄、輕巧等特性。</P>"+
                       "<p class='PcDetCenterFoot'>【產品標準】</P>"+
   "<p class='PcDetCenterFoot'>完美支架，包含與支架整體鑄造出來的固位卡環、頜支托、前牙區固位小釘。</P>"+
   "<img class='PcDetimgBox' src="+require('../../assets/MobileImg/ProductDetImgFile/productgrup5/1/grupimg5_1.png')+">"+
   "<img class='PcDetimgBox' src="+require('../../assets/MobileImg/ProductDetImgFile/productgrup5/1/grupimg5_2.png')+">",
   productTypeCNimg5_2:
   "<p class='PcDetCenterFoot'>【產品名稱】</P>"+
   "<p class='PcDetCenterFoot'>維他靈支架 / Vitallium Denture System"+
   "<p class='PcDetCenterFoot'>【品牌介紹】</p>"+
   "<p class='PcDetCenterFoot'>世界500強企業美國登士柏公司的優質金屬支架材料。</p>"+
   "<p class='PcDetCenterFoot'>【適用範圍】</p>"+
   "<p class='PcDetCenterFoot'>牙列缺損的活動修復用支架。</p>"+
   "<p class='PcDetCenterFoot'>【產品特點】</P>"+
   "<p class='PcDetCenterFoot'>1、卓越的物理性能和操作性能；</p>"+
   "<p class='PcDetCenterFoot'>2、可用於粘膜承力式應力中斷、應力釋放可摘局部義齒支架設計；</p>"+
   "<p class='PcDetCenterFoot'>3、為游離端缺失的治療提供了更好的解決方案。</p>"+
                       "<p class='PcDetCenterFoot'>【產品標準】</P>"+
   "<p class='PcDetCenterFoot'>* 維他靈支架，包含與支架整體鑄造出來的固位卡環、頜支托、前牙區固位小釘。</P>"+
   "<img class='PcDetimgBox' src="+require('../../assets/MobileImg/ProductDetImgFile/productgrup5/2/grupimg5_3.png')+">"+
   "<img class='PcDetimgBox' src="+require('../../assets/MobileImg/ProductDetImgFile/productgrup5/2/grupimg5_4.png')+">",
   productTypeCNimg5_3:
   "<p class='PcDetCenterFoot'>【產品名稱】</P>"+
   "<p class='PcDetCenterFoot'>純鈦鋼托支架 </p>"+
   "<p class='PcDetCenterFoot'>【品牌介紹】</p>"+
   "<p class='PcDetCenterFoot'>進口純鈦，日本產。</p>"+
   "<p class='PcDetCenterFoot'>【適用範圍】</p>"+
   "<p class='PcDetCenterFoot'>牙列缺損的活動修復用支架。</p>"+
   "<p class='PcDetCenterFoot'>【產品特點】</P>"+
   "<p class='PcDetCenterFoot'>具有優越生物相容性、彈性、輕巧等特性。</p>"+
                       "<p class='PcDetCenterFoot'>【產品標準】</P>"+
   "<p class='PcDetCenterFoot'>純鈦鋼托支架，包含與支架整體鑄造出來的固位卡環、頜支托、前牙區固位小釘。</P>"+
   "<img class='PcDetimgBox' src="+require('../../assets/MobileImg/ProductDetImgFile/productgrup5/3/grupimg5_5.png')+">"+
   "<img class='PcDetimgBox' src="+require('../../assets/MobileImg/ProductDetImgFile/productgrup5/3/grupimg5_6.png')+">",
   productTypeCNimg5_4:
   "<p class='PcDetCenterFoot'>【產品名稱】</P>"+
   "<p class='PcDetCenterFoot'>聚醚醚酮peek支架 </p>"+
   "<p class='PcDetCenterFoot'>【適用範圍】</p>"+
   "<p class='PcDetCenterFoot'>1、可摘義齒，美觀，輕巧</p>"+
   "<p class='PcDetCenterFoot'>2、密度小、品質輕、異物感相對小；</p>"+
   "<p class='PcDetCenterFoot'>3、可摘義齒，種植體上部，套筒冠，杆卡結構修復體等; </p>"+
   "<p class='PcDetCenterFoot'>4、义齿新型材料。</p>"+
   "<p class='PcDetCenterFoot'>【產品特點】</p>"+
   "<p class='PcDetCenterFoot'>1、材料主要特：易加工，耐腐蝕性好，比金屬輕，純Peek生物相容性佳等；</p>"+
   "<p class='PcDetCenterFoot'>2、生理學特徵：有彈性、減震、對餘留牙齒無磨損、彈性如同頜骨·耐斷裂和扭轉、包容性強、耐磨損；</p>"+
   "<p class='PcDetCenterFoot'>3、生物相容性：不含金屬、防過敏、不溶于水、抗菌斑、防導電、化學性質穩定，相比於陶瓷能保持固有強度·，無老化變質或退化，透X光性能好，方便檢測術後恢復；</p>"+
   "<p class='PcDetCenterFoot'>4、患者滿意度高：自然美、自然的咀嚼能力，性價比高、不染色、在口腔裡感覺輕鬆自然·比剛性修復更令人愉悅；</p>"+
   "<p class='PcDetCenterFoot'>5、加工優點：易研磨、易拋光、易貼面。</p>"+
   "<img class='PcDetimgBox' src="+require('../../assets/MobileImg/ProductDetImgFile/productgrup5/4/grupimg5_7.png')+">"+
   "<img class='PcDetimgBox' src="+require('../../assets/MobileImg/ProductDetImgFile/productgrup5/4/grupimg5_8.png')+">",
   productTypeCNimg5_5:
   "<p class='PcDetCenterFoot'>【產品名稱】</P>"+
   "<p class='PcDetCenterFoot'>全口義齒 </p>"+
   "<p class='PcDetCenterFoot'>【適用範圍】</p>"+
   "<p class='PcDetCenterFoot'>適用於全口牙列缺失的修復，此處指由黏膜及膜片下組織支持的常規全口義齒。</p>"+
   "<p class='PcDetCenterFoot'>【產品特點】</p>"+
   "<p class='PcDetCenterFoot'>可摘義齒，美觀，輕巧。</p>"+
   "<p class='PcDetCenterFoot'>【產品標準】</p>"+
   "<p class='PcDetCenterFoot'>普通塑膠基托 + 普通樹脂牙（凱豐膠牙）。</p>"+
   "<p class='PcDetCenterFoot'>【臨床建議】</p>"+
   "<p class='PcDetCenterFoot'>1、建議先製作個別託盤，以便取得準確的印模。</p>"+
   "<p class='PcDetCenterFoot'>2、可選擇高端基托材料如199不碎膠及高端樹脂牙，但需加收費用。</p>"+
   "<img class='PcDetimgBox' src="+require('../../assets/MobileImg/ProductDetImgFile/productgrup5/5/grupimg5_9.png')+">",
   productTypeCNimg5_6:
   "<p class='PcDetCenterFoot'>【產品名稱】</P>"+
   "<p class='PcDetCenterFoot'> Snap on smile</p>"+
   "<p class='PcDetCenterFoot'>【適用範圍】</p>"+
   "<p class='PcDetCenterFoot'>四環素牙、局部缺頭、牙列不整齊</p>"+
   "<p class='PcDetCenterFoot'>【產品特點】</P>"+
   "<p class='PcDetCenterFoot'>1、可摘義齒，美觀，輕巧。</P>"+
   "<p class='PcDetCenterFoot'>2、不用備牙</P>"+
   "<img class='PcDetimgBox' src="+require('../../assets/MobileImg/ProductDetImgFile/productgrup5/6/grupimg5_10.png')+">"+
   "<img class='PcDetimgBox' src="+require('../../assets/MobileImg/ProductDetImgFile/productgrup5/6/grupimg5_11.png')+">",
   productAnnexObj1:
   "<p class='PcDetCenterFoot'>【產品名稱】</P>"+
   "<p class='PcDetCenterFoot'>快模式（Snap）</p>"+
   "<p class='PcDetCenterFoot'>【適用範圍】</p>"+
   "<p class='PcDetCenterFoot'>適用于單、雙側游離端缺失修複和種植覆蓋義齒修復（單側游離缺失須在對側設計支抗）。</p>"+
   "<p class='PcDetCenterFoot'>【產品特點】</P>"+
   "<p class='PcDetCenterFoot'> * 冠外附著體，錨式；</P>"+
   "<p class='PcDetCenterFoot'> * 放置在基牙鄰面、唇面或杆卡，所需空間較少；</P>"+
   "<p class='PcDetCenterFoot'> * 牙頜齦間距所需空間較少；</P>"+
   "<p class='PcDetCenterFoot'> * 彈性緩衝。</P>"+
   "<p class='PcDetCenterFoot'>【工藝流程】</P>"+
   "<p class='PcDetCenterFoot'> 分兩次完成 ：</P>"+
   "<p class='PcDetCenterFoot'> * 第一次完成固定部分；</P>"+
   "<p class='PcDetCenterFoot'> * 第二次完成活動部分（做活動修復時，固定牙部分需帶牙取模一起返回）；</P>"+
   "<p class='PcDetCenterFoot'>【臨床建議】</P>"+
   "<p class='PcDetCenterFoot'> * 分兩次完成：第一次固定烤瓷部分在技工中心完成後返回臨床試戴，試戴合適後需戴著烤瓷固定部分，再用矽橡膠取模。取模時，建議您用較稀的少量丁香油或氧化鋅將精密附著體固定烤瓷部分臨時粘接後再取。</P>"+
   "<p class='PcDetCenterFoot'> * 如需自行灌模，請您在灌模前一定要在固定冠底部均勻塗一層凡士林，方便牙冠能順利從石膏模型上取下，減少頸緣崩瓷。</P>"+
   "<img class='PcDetimgBox' src="+require('../../assets/MobileImg/productAnnexImgFile/1/productAnnexImg1.png')+">"+
   "<img class='PcDetimgBox' src="+require('../../assets/MobileImg/productAnnexImgFile/1/productAnnexImg2.png')+">"+
   "<img class='PcDetimgBox' src="+require('../../assets/MobileImg/productAnnexImgFile/1/productAnnexImg3.png')+">",
productAnnexObj2:
   "<p class='PcDetCenterFoot'>【產品名稱】</P>"+
   "<p class='PcDetCenterFoot'> * 栓道式 /（Preci-line）</p>"+
   "<p class='PcDetCenterFoot'>【適用範圍】</p>"+
   "<p class='PcDetCenterFoot'> * 適用于單、雙側游離缺失修複或局部缺失修複（單側游離缺失須在對側設計支抗）；</p>"+
   "<p class='PcDetCenterFoot'>【產品特點】</p>"+
   "<p class='PcDetCenterFoot'> * 摩擦力固位，墊圈可更換；</p>"+
   "<p class='PcDetCenterFoot'> * 對基牙有一定的保護作用；</p>"+
   "<p class='PcDetCenterFoot'> * 彈性緩衝。</p>"+
   "<p class='PcDetCenterFoot'>【臨床建議】</P>"+
   "<p class='PcDetCenterFoot'> * 分兩次完成，第一次固定烤瓷部分在技工中心完成後返回臨床試戴，試戴合適後需戴著烤瓷固定部分，再用矽橡膠取模。取模時，建議您用較稀的少量丁香油或氧化鋅將精密附著體固定烤瓷部分臨時粘接後再取。</p>"+
   "<p class='PcDetCenterFoot'> * 如需自行灌模，請您在灌模前一定要在固定冠底部均勻塗一層凡士林，方便牙冠能順利從石膏模型上取下，減少頸緣崩瓷。凡士林太多也會造成固定冠定位不準確，活動托就位困難。</p>"+
   "<img class='PcDetimgBox' src="+require('../../assets/MobileImg/productAnnexImgFile/2/productAnnexImg4.jpg')+">"+
   "<img class='PcDetimgBox' src="+require('../../assets/MobileImg/productAnnexImgFile/2/productAnnexImg5.jpg')+">",
productAnnexObj3:
   "<p class='PcDetCenterFoot'>【產品名稱】</P>"+
   "<p class='PcDetCenterFoot'> * 栓道式 /（Preci-line）</p>"+
   "<p class='PcDetCenterFoot'>【適用範圍】</p>"+
   "<p class='PcDetCenterFoot'> * 適用于單、雙側游離缺失修複或局部缺失修複（單側游離缺失須在對側設計支抗）；</p>"+
   "<p class='PcDetCenterFoot'>【產品特點】</p>"+
   "<p class='PcDetCenterFoot'> * 摩擦力固位，墊圈可更換；</p>"+
   "<p class='PcDetCenterFoot'> * 對基牙有一定的保護作用；</p>"+
   "<p class='PcDetCenterFoot'> * 彈性緩衝。</p>"+
   "<p class='PcDetCenterFoot'>【臨床建議】</P>"+
   "<p class='PcDetCenterFoot'> * 分兩次完成，第一次固定烤瓷部分在技工中心完成後返回臨床試戴，試戴合適後需戴著烤瓷固定部分，再用矽橡膠取模。取模時，建議您用較稀的少量丁香油或氧化鋅將精密附著體固定烤瓷部分臨時粘接後再取。</p>"+
   "<p class='PcDetCenterFoot'> * 如需自行灌模，請您在灌模前一定要在固定冠底部均勻塗一層凡士林，方便牙冠能順利從石膏模型上取下，減少頸緣崩瓷。凡士林太多也會造成固定冠定位不準確，活動托就位困難。</p>"+
   "<img class='PcDetimgBox' src="+require('../../assets/MobileImg/productAnnexImgFile/3/productAnnexImg6.jpg')+">"+
   "<img class='PcDetimgBox' src="+require('../../assets/MobileImg/productAnnexImgFile/3/productAnnexImg7.jpg')+">",
productAnnexObj4:
   "<p class='PcDetCenterFoot'>【產品名稱】</P>"+
   "<p class='PcDetCenterFoot'> 根帽附著體（Scop）</p>"+
   "<p class='PcDetCenterFoot'>【適用範圍】</p>"+
   "<p class='PcDetCenterFoot'> 主要適用於覆蓋義齒，一個或多個殘根作為固位體均可。比杆卡精密附件更容易清潔。如果作為固位的某個殘根要拔除，根帽精密附件往往更容易改造。</p>"+
   "<p class='PcDetCenterFoot'>【產品標準】</p>"+
   "<p class='PcDetCenterFoot'> 球狀陽性部件為塑膠可鑄原件，與樁核整體鑄造，陰性部件為鈦金屬。</p>"+
   "<p class='PcDetCenterFoot'>【臨床建議】</P>"+
   "<p class='PcDetCenterFoot'> 建議活動部分的附著體臨床粘接會更準確。</p>"+
   "<img class='PcDetimgBox' src="+require('../../assets/MobileImg/productAnnexImgFile/4/productAnnexImg8.png')+">"+
   "<img class='PcDetimgBox' src="+require('../../assets/MobileImg/productAnnexImgFile/4/productAnnexImg9.jpg')+">"+
   "<img class='PcDetimgBox' src="+require('../../assets/MobileImg/productAnnexImgFile/4/productAnnexImg10.jpg')+">",

productAnnexObj5:
   "<p class='PcDetCenterFoot'>【產品名稱】</P>"+
   "<p class='PcDetCenterFoot'> 根帽附著體（Scop）</p>"+
   "<p class='PcDetCenterFoot'>【適用範圍】</p>"+
   "<p class='PcDetCenterFoot'> 主要適用於覆蓋義齒，一個或多個殘根作為固位體均可。比杆卡精密附件更容易清潔。如果作為固位的某個殘根要拔除，根帽精密附件往往更容易改造。</p>"+
   "<p class='PcDetCenterFoot'>【產品標準】</p>"+
   "<p class='PcDetCenterFoot'> 球狀陽性部件為塑膠可鑄原件，與樁核整體鑄造，陰性部件為鈦金屬。</p>"+
   "<p class='PcDetCenterFoot'>【臨床建議】</P>"+
   "<p class='PcDetCenterFoot'> 建議活動部分的附著體臨床粘接會更準確。</p>"+
   "<img class='PcDetimgBox' src="+require('../../assets/MobileImg/productAnnexImgFile/5/productAnnexImg11.png')+">"+
   "<img class='PcDetimgBox' src="+require('../../assets/MobileImg/productAnnexImgFile/5/productAnnexImg12.jpg')+">"+
   "<img class='PcDetimgBox' src="+require('../../assets/MobileImg/productAnnexImgFile/5/productAnnexImg13.jpg')+">",
productAnnexObj6:
   "<p class='PcDetCenterFoot'>【產品名稱】</P>"+
   "<p class='PcDetCenterFoot'> * 杆卡附著體（BAR）</p>"+
   "<p class='PcDetCenterFoot'>【適用範圍】</p>"+
   "<p class='PcDetCenterFoot'> * 主要適用於覆蓋義齒，有兩個或以上的殘根固位，或是種植上部用。</p>"+
   "<p class='PcDetCenterFoot'>【產品標準】</p>"+
   "<p class='PcDetCenterFoot'> * 杆水準放置，離開牙槽脊2MM,每段杆配一個夾子；</p>"+
   "<p class='PcDetCenterFoot'> * 杆與樁核或種植體焊接；</p>"+
   "<p class='PcDetCenterFoot'> * 游離端使用杆長不超過8MM；</p>"+
   "<p class='PcDetCenterFoot'> * 可選擇鑄造杆、成品黃金杆。；</p>"+
   "<p class='PcDetCenterFoot'>【臨床建議】</P>"+
   "<p class='PcDetCenterFoot'> * 建議活動部分的附著體臨床粘接會更準確。</p>"+
   "<img class='PcDetimgBox' src="+require('../../assets/MobileImg/productAnnexImgFile/6/productAnnexImg14.jpg')+">"+
   "<img class='PcDetimgBox' src="+require('../../assets/MobileImg/productAnnexImgFile/6/productAnnexImg15.png')+">",
}