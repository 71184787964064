<template>
    <div>
        <el-carousel  arrow="always" class="PCmy-swipe" indicator-color="white" style="height: 870px;">
            <el-carousel-item style="height: 870px;">
                <img class="PClunboBox" v-if="$t('language.language') === 'Chinese'" src="../../assets/PCimg/homeFileImg/homelunbo3EN.jpg" alt="banner1">
                <img class="PClunboBox" v-else src="../../assets/PCimg/homeFileImg/homelunbo3.png" alt="banner1">
            </el-carousel-item >
            <el-carousel-item style="height: 870px;">
                <img class="PClunboBox" v-if="$t('language.language') === 'Chinese'" src="../../assets/PCimg/homeFileImg/homelunbo2EN.jpg" alt="banner1">
                <img class="PClunboBox" v-else src="../../assets/PCimg/homeFileImg/homelunbo2.jpg" alt="banner1">
            </el-carousel-item>
            <el-carousel-item style="height: 870px;"><img class="PClunboBox" src="../../assets/PCimg/homeFileImg/homelunbo1.jpg" alt="banner3"></el-carousel-item >
        </el-carousel> 
        
        <div class="swiperBox">
            <!-- v-if="$t('language.language') === 'Chinese'" -->
            <video v-if="$t('language.language') === 'Chinese'"
                    muted
                    autoplay
                    x5-playsinline="true"
                    playsinline="true" 
                    style="background:#000" 
                    controlslist="nodownload" 
                    src="../../assets/videoFile/ENvideolunbo.mp4" 
                    width="100%" 
                    controls="controls"
                    class="PCvideo_url_play">
            </video>
            <video v-else
                    muted
                    autoplay
                    x5-playsinline="true"
                    playsinline="true" 
                    style="background:#000" 
                    controlslist="nodownload" 
                    src="../../assets/videoFile/CNvideolunbo.mp4" 
                    width="100%" 
                    controls="controls"
                    class="PCvideo_url_play">
            </video>
        </div> 
    </div>
</template>
<script>
export default {
    name:'home',
    data(){
        return{
            imgHeight:'0',
        }
    },
    mounted(){

    },
    destroyed(){

    },
    methods:{

    }
}
</script>
<style lang="less">
    .PCmy-swipe{
         height: 870px;
        .PClunboBox{
            width:100%;
            height:100%;
        }
        .el-carousel__container{
                height: 870px;
        }
        .el-carousel__indicators{
            .el-carousel__indicator{
                .el-carousel__button{
                    width: 17px;
                    height: 17px;
                    border-radius: 50%;
                }
                
            }
        }
    }  
    .PCvideo_url_play{
        width:1236px;  height:671px;
    }
    .swiperBox{
        padding-top:130px;
        background: black;
        height:750px;
    }
</style>