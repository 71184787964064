<template>
    <div class="productBox">
        <div class="production_top">
            <img class="production_top" src="../../assets/MobileImg/pruductTopImg.png" alt="图片">
        </div>
        <van-tabbar v-model="active" @change="changeProduct" :fixed="false" :border="false" active-color="#2595B7">
            <van-tabbar-item name="productType1">
                <div>
                    <p class="productAnnexTitle">{{$t('productObj.productType1')}}</p>
                    <p v-if="$t('language.language') === 'Chinese'" class="productAnnexTitle">series</p>
                </div>
                <template #icon="props">
                    <img class="pruductionTypeImgBox" src="../../assets/MobileImg/pruductionTypeImg1.png" />
                </template>
            </van-tabbar-item>
            <van-tabbar-item name="productType2">
                <div>
                    <p class="productAnnexTitle">{{$t('productObj.productType2')}}</p>
                    <p v-if="$t('language.language') === 'Chinese'" class="productAnnexTitle">series</p>
                </div>
                <template #icon="props">
                    <img class="pruductionTypeImgBox" src="../../assets/MobileImg/pruductionTypeImg2.png" />
                </template>
            </van-tabbar-item>
            <van-tabbar-item name="productType3">
                <div>
                    <!-- Denture -->
                    <p class="productAnnexTitle">{{$t('language.language') === 'Chinese' ? 'Precision Attachment' : '精密附件'}}</p>
                    <p v-if="$t('language.language') === 'Chinese'" class="productAnnexTitle">restoration</p>
                </div>
                <template #icon="props">
                    <img class="pruductionTypeImgBox" src="../../assets/MobileImg/pruductionTypeImg3.png" />
                </template>
            </van-tabbar-item>
            <van-tabbar-item name="productType4">
                <div>
                    <p class="productAnnexTitle">{{$t('productObj.productType4')}}</p>
                    <p v-if="$t('language.language') === 'Chinese'" class="productAnnexTitle">device</p>
                </div>
                <template #icon="props">
                    <img class="pruductionTypeImgBox" src="../../assets/MobileImg/pruductionTypeImg4.png" />
                </template>
            </van-tabbar-item>
            <van-tabbar-item name="productType5" >
                <div>
                    <p class="productAnnexTitle">{{$t('productObj.productType5')}}</p>
                    <p v-if="$t('language.language') === 'Chinese'" class="productAnnexTitle">Repair</p>
                </div>
                <template #icon="props">
                    <img class="pruductionTypeImgBox5" src="../../assets/MobileImg/pruductionTypeImg5.jpg" />
                </template>
            </van-tabbar-item>
        </van-tabbar>
        <div class="productCenterBox" >
            <div class="productCenterBox_box" v-for="(item,index) in imgList" @click="productDet(item)">
                <img class="productCenterBox_Img" :src="item.CNurl" :key="index"/>
                <p class="productCenterBox_name" v-show="$t('language.language') === 'English'">{{item.CNname}}</p>
                <p class="productCenterBox_name" v-show="$t('language.language') === 'Chinese'">{{item.ENname}}</p>
            </div>
        </div>
         <div class="productFooterBox">
             <div>
                <img class="productFooteimg" v-if="$t('language.language') === 'Chinese'" src="../../assets/MobileImg/productFooteimgEN.jpg" alt="banner1">
                <img class="productFooteimg" v-else src="../../assets/MobileImg/productFooteimgCN.jpg" alt="banner1">
             </div>
             <div>
                <img class="productFooteimg" v-if="$t('language.language') === 'Chinese'" src="../../assets/MobileImg/productFooteimgEN2.jpg" alt="banner1">
                <img class="productFooteimg" v-else src="../../assets/MobileImg/productFooteimgCN2.jpg" alt="banner1">
             </div>
        </div>
    </div>
</template>
<script>
export default {
    name:'productPage',
    data(){
        return{
            active:this.$route.query.active || 'productType3',
            imgList:[],
            productType1List:[
                {
                    ENurl:require('../../assets/MobileImg/productFlieImgs/ENImg/productTypeENimg1/1.png'),
                    CNurl:require('../../assets/MobileImg/productFlieImgs/CNImg/productTypeCNimg1/1.jpg'),
                    CNname:'威兰德全瓷',
                    ENname:'Wieland Zirconia',
                    value:'productTypeCNimg1_1'
                },
                {
                    ENurl:require('../../assets/MobileImg/productFlieImgs/ENImg/productTypeENimg1/2.png'),
                    CNurl:require('../../assets/MobileImg/productFlieImgs/CNImg/productTypeCNimg1/2.jpg'),
                    CNname:'Ablaze全瓷',
                    ENname:'Ablaze Zirconia',
                    value:'productTypeCNimg1_2'
                },
                {
                    ENurl:require('../../assets/MobileImg/productFlieImgs/ENImg/productTypeENimg1/3.png'),
                    CNurl:require('../../assets/MobileImg/productFlieImgs/CNImg/productTypeCNimg1/3.jpg'),
                    CNname:'易美全瓷',
                    ENname:'IPS e.max',
                    value:'productTypeCNimg1_3'
                },
                {
                    ENurl:require('../../assets/MobileImg/productFlieImgs/ENImg/productTypeENimg1/4.png'),
                    CNurl:require('../../assets/MobileImg/productFlieImgs/CNImg/productTypeCNimg1/4.jpg'),
                    CNname:'LAVA全瓷',
                    ENname:'Lava Zirconia',
                    value:'productTypeCNimg1_4'
                },
                {
                    ENurl:require('../../assets/MobileImg/productFlieImgs/ENImg/productTypeENimg1/5.png'),
                    CNurl:require('../../assets/MobileImg/productFlieImgs/CNImg/productTypeCNimg1/5.png'),
                    CNname:'易美全瓷',
                    ENname:'IPS e.max',
                    value:'productTypeCNimg1_5'
                },
                {
                    ENurl:require('../../assets/MobileImg/productFlieImgs/ENImg/productTypeENimg1/6.png'),
                    CNurl:require('../../assets/MobileImg/productFlieImgs/CNImg/productTypeCNimg1/6.jpg'),
                    CNname:'LAVA全瓷',
                    ENname:'Lava Zirconia',
                    value:'productTypeCNimg1_6'
                },
            ],
            productType2List:[
                {
                    ENurl:require('../../assets/MobileImg/productFlieImgs/ENImg/productTypeENimg2/1.png'),
                    CNurl:require('../../assets/MobileImg/productFlieImgs/CNImg/productTypeCNimg2/1.jpg'),
                    CNname:'生物鐳射烤瓷',
                    ENname:'Laser Print',
                    value:'productTypeCNimg2_1'
                },
                {
                    ENurl:require('../../assets/MobileImg/productFlieImgs/ENImg/productTypeENimg2/2.png'),
                    CNurl:require('../../assets/MobileImg/productFlieImgs/CNImg/productTypeCNimg2/2.jpg'),
                    CNname:'金屬嵌體',
                    ENname:'Metal Print',
                    value:'productTypeCNimg2_2'
                },
                {
                    ENurl:require('../../assets/MobileImg/productFlieImgs/ENImg/productTypeENimg2/3.png'),
                    CNurl:require('../../assets/MobileImg/productFlieImgs/CNImg/productTypeCNimg2/3.jpg'),
                    CNname:'金屬嵌體',
                    ENname:'Metal Print',
                    value:'productTypeCNimg2_3'
                },
                {
                    ENurl:require('../../assets/MobileImg/productFlieImgs/ENImg/productTypeENimg2/4.png'),
                    CNurl:require('../../assets/MobileImg/productFlieImgs/CNImg/productTypeCNimg2/4.jpg'),
                    CNname:'金屬烤瓷冠',
                    ENname:'Metal Inlay',
                    value:'productTypeCNimg2_4'
                },
                {
                    ENurl:require('../../assets/MobileImg/productFlieImgs/ENImg/productTypeENimg2/5.png'),
                    CNurl:require('../../assets/MobileImg/productFlieImgs/CNImg/productTypeCNimg2/5.jpg'),
                    CNname:'馬里蘭橋',
                    ENname:'Maryland Bridge',
                    value:'productTypeCNimg2_5'
                },
                {
                    ENurl:require('../../assets/MobileImg/productFlieImgs/ENImg/productTypeENimg2/6.png'),
                    CNurl:require('../../assets/MobileImg/productFlieImgs/CNImg/productTypeCNimg2/6.jpg'),
                    CNname:'馬里蘭橋',
                    ENname:'Maryland Bridge',
                    value:'productTypeCNimg2_6'
                },
            ],
            productType3List:[
                {
                    ENurl:require('../../assets/MobileImg/productAnnexImg1.jpg'),
                    CNurl:require('../../assets/MobileImg/productAnnexImg1.jpg'),
                    CNname:'精密附件快模式',
                    ENname:'(Snap)',
                    value:'productAnnexObj1'
                },
                {
                    ENurl:require('../../assets/MobileImg/productAnnexImg2.jpg'),
                    CNurl:require('../../assets/MobileImg/productAnnexImg2.jpg'),
                    CNname:'精密附件栓道式',
                    ENname:'Slot Attachment',
                    value:'productAnnexObj2'
                },
                {
                    ENurl:require('../../assets/MobileImg/productAnnexImg3.jpg'),
                    CNurl:require('../../assets/MobileImg/productAnnexImg3.jpg'),
                    CNname:'精密附件栓道式',
                    ENname:'Slot Attachment',
                    value:'productAnnexObj3'
                },
                {
                    ENurl:require('../../assets/MobileImg/productAnnexImg4.jpg'),
                    CNurl:require('../../assets/MobileImg/productAnnexImg4.jpg'),
                    CNname:'精密附件根帽附著體',
                    ENname:'（scop）',
                    value:'productAnnexObj4'
                },
                {
                    ENurl:require('../../assets/MobileImg/productAnnexImg5.jpg'),
                    CNurl:require('../../assets/MobileImg/productAnnexImg5.jpg'),
                    CNname:'精密附件根帽附著體',
                    ENname:'（scop）',
                    value:'productAnnexObj5'
                },
                {
                    ENurl:require('../../assets/MobileImg/productAnnexImg6.jpg'),
                    CNurl:require('../../assets/MobileImg/productAnnexImg6.jpg'),
                    CNname:'精密附件杆卡附著體',
                    ENname:'(Snap)',
                    value:'productAnnexObj6'
                },
            ], 
            productType4List:[
                {
                    ENurl:require('../../assets/MobileImg/productFlieImgs/ENImg/productTypeENimg4/1.jpg'),
                    CNurl:require('../../assets/MobileImg/productFlieImgs/CNImg/productTypeCNimg4/1.png'),
                    CNname:'护磨牙墊',
                    ENname:'Night Guard',
                    value:'productTypeCNimg4_1'
                },
                {
                    ENurl:require('../../assets/MobileImg/productFlieImgs/ENImg/productTypeENimg4/2.jpg'),
                    CNurl:require('../../assets/MobileImg/productFlieImgs/CNImg/productTypeCNimg4/2.jpg'),
                    CNname:'防鼾器',
                    ENname:'Snoring Guard',
                    value:'productTypeCNimg4_2'
                },
                {
                    ENurl:require('../../assets/MobileImg/productFlieImgs/ENImg/productTypeENimg4/3.jpg'),
                    CNurl:require('../../assets/MobileImg/productFlieImgs/CNImg/productTypeCNimg4/3.png'),
                    CNname:'法蘭克爾矯正器',
                    ENname:'Frankel Appliance',
                    value:'productTypeCNimg4_3'
                },
                {
                    ENurl:require('../../assets/MobileImg/productFlieImgs/ENImg/productTypeENimg4/4.jpg'),
                    CNurl:require('../../assets/MobileImg/productFlieImgs/CNImg/productTypeCNimg4/4.jpg'),
                    CNname:'哈利氏保持器',
                    ENname:'Hawley Retainer',
                    value:'productTypeCNimg4_4'
                },
                {
                    ENurl:require('../../assets/MobileImg/productFlieImgs/ENImg/productTypeENimg4/5.jpg'),
                    CNurl:require('../../assets/MobileImg/productFlieImgs/CNImg/productTypeCNimg4/5.jpg'),
                    CNname:'運動護齒套',
                    ENname:'Sport Guard',
                    value:'productTypeCNimg4_5'
                },
                {
                    ENurl:require('../../assets/MobileImg/productFlieImgs/ENImg/productTypeENimg4/6.jpg'),
                    CNurl:require('../../assets/MobileImg/productFlieImgs/CNImg/productTypeCNimg4/6.jpg'),
                    CNname:'螺旋矯正器',
                    ENname:'Expansion Plate',
                    value:'productTypeCNimg4_6'
                },
            ],
            productType5List:[
                {
                    ENurl:require('../../assets/MobileImg/productFlieImgs/ENImg/productTypeENimg5/1.png'),
                    CNurl:require('../../assets/MobileImg/productFlieImgs/CNImg/productTypeCNimg5/1.jpg'),
                    CNname:'德國BPD完美支架',
                    ENname:'Bego Framework',
                    value:'productTypeCNimg5_1'
                },
                {
                    ENurl:require('../../assets/MobileImg/productFlieImgs/ENImg/productTypeENimg5/2.png'),
                    CNurl:require('../../assets/MobileImg/productFlieImgs/CNImg/productTypeCNimg5/2.jpg'),
                    CNname:'維他靈支架',
                    ENname:'Vitallium 2000 Framework',
                    value:'productTypeCNimg5_2'
                },
                {
                    ENurl:require('../../assets/MobileImg/productFlieImgs/ENImg/productTypeENimg5/3.png'),
                    CNurl:require('../../assets/MobileImg/productFlieImgs/CNImg/productTypeCNimg5/3.jpg'),
                    CNname:'純鈦鋼托支架',
                    ENname:'Titanium Framework',
                    value:'productTypeCNimg5_3'
                },
                {
                    ENurl:require('../../assets/MobileImg/productFlieImgs/ENImg/productTypeENimg5/4.png'),
                    CNurl:require('../../assets/MobileImg/productFlieImgs/CNImg/productTypeCNimg5/4.jpg'),
                    CNname:'聚醚醚酮peek支架',
                    ENname:'Peek Framework',
                    value:'productTypeCNimg5_4'
                },
                {
                    ENurl:require('../../assets/MobileImg/productFlieImgs/ENImg/productTypeENimg5/5.png'),
                    CNurl:require('../../assets/MobileImg/productFlieImgs/CNImg/productTypeCNimg5/5.jpg'),
                    CNname:'全口義齒',
                    ENname:'Full Denture',
                    value:'productTypeCNimg5_5'
                },
                {
                    ENurl:require('../../assets/MobileImg/productFlieImgs/ENImg/productTypeENimg5/6.png'),
                    CNurl:require('../../assets/MobileImg/productFlieImgs/CNImg/productTypeCNimg5/6.jpg'),
                    CNname:'Snap on smile',
                    ENname:'Snap on smile',
                    value:'productTypeCNimg5_6'
                },
            ],
        }
    },
    mounted(){
        this.changeProduct(this.active)
    },
    methods:{
        changeProduct(v){      
            switch (v) {
                case 'productType1':
                    this.imgList = this.productType1List;
                    break;
                case 'productType2':
                    this.imgList = this.productType2List;
                    break;
                case 'productType3':
                    this.imgList = this.productType3List;
                    break;
                case 'productType4':
                    this.imgList = this.productType4List;
                    break;
                case 'productType5':
                    this.imgList = this.productType5List;
                    break;
                case 'productType6':
                    this.imgList = this.productType6List;
                    break;
                default:
                    console.log('暂无数据')
                    break;
            }
        },
        productDet(item){
            if(this.active === 'productType3'){
                this.$router.push({name:'productAnnexDet',query:{active:this.active,value:item.value,type:'productAnnex'}})
            }else{
                this.$router.push({name:'productDetPage',query:{active:this.active,value:item.value,type:'productAnnex'}})
            }
            // window.location.reload();
        }
    }
}
</script>
<style lang="less">
.productBox{
    .production_top{
        width: 100%;
        height: 7.2rem;
    }
    .productAnnexTitle{
        // font-size: 0.3rem;
        height: 1.5rem;
        margin: 5px 0;
    }
    .van-tabbar{
        height: 8.5rem;
    }
    .productCenterBox{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        box-sizing: border-box;
        width: 100%;
        padding:0 10px;
    }
    .productCenterBox_box{
        margin-bottom:7px; 
        position: relative;
    }
    .productCenterBox_Img{
        width: 6.9rem;
        height:6.5rem;
    }
    .productCenterBox_name{
        position: absolute;
        bottom: 3px;
        margin: 0;
        width: 100%;
        color: white;
        overflow: hidden;
        white-space: nowrap; 
        font-size: 0.7rem;
        background: rgb(0 0 0 / 40%);
    }
    .pruductionTypeImgBox{
       height: 3.5rem;
        height: 3.5rem;
    }
    .productFooterBox{
        display: flex;
        justify-content: space-between;
        box-sizing: border-box;
        padding:0 10px;
        width: 100%;
        .productFooteimg{
            width: 10.7rem;
            height: 14.5rem;;
        }
    }
    .pruductionTypeImgBox5{
        width: 3.5rem;
        height: 3.5rem;
        border-radius:2.5rem;
    }
}
</style>


