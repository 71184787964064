import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router'
import {router} from './router/index.js'
import Vant from 'vant';
import 'vant/lib/index.css';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import VueI18n from 'vue-i18n'
Vue.config.productionTip = false

Vue.use(VueI18n)
Vue.use(VueRouter)
Vue.use(Vant);
Vue.use(ElementUI);

var messages = {};
let Adaptive = /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
if (navigator.userAgent.match(Adaptive)) {
    messages = {
        'zh':require('./locale/Mobilelang/zh'),
        'en':require('./locale/Mobilelang/en')
    }
} else {
  messages = {
      'zh':require('./locale/PClang/zh'),
      'en':require('./locale/PClang/en')
  }
}
const i18n=new VueI18n({
  locale:localStorage.getItem('languageSet')||'en',   //从localStorage里获取用户中英文选择，没有则默认中文
  messages
})
new Vue({
  router,
  i18n,
  render: h => h(App),
}).$mount('#app')
