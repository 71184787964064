<template>
    <div>
        <Heads/>
          <router-view></router-view>
        <Footer/>
    </div>
</template>
<script>
import Heads from '../../components/MobileComponents/Heads/index'
import Footer from '../../components/MobileComponents/Footer/index'
export default {
    components:{Heads,Footer},
    name:'MobileHome',
    data(){
        return{

        }
    },
    mounted(){

    },
    methods:{

    }
}
</script>
