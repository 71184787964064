module.exports = {
    language:{
        language:'Chinese'
    },
    menuObj:{
        menuName1:'HOME',
        menuName2:'NEW TECHNIQUE',
        menuName3:'PRODUCT',
        menuName4:'ENTERPRISE',
        menuName5:'CONTACT',
    },
    ContactUsObj:{
        Contact1:'phone number:+0085269499181',
        Contact2:'WeChat:18923831515',
        Contact3:'e-mail:cnpassport.alibaba.com',
        Contact4:'Location:UNIT C 2/F KWONG ON BANK  MONGKOK BRANCH BUILDING 728-730 NATHAN ROAD MONGKOK KL',
    },
    search:{
        name:'keywords'
    }
}