<template>
    <div class="PCproductDet_box">
        <div class="PCproductDet_header">
            <img class="PCproductDet_header" src="../../assets/PCimg/PCproductImgs/productBG.jpg" alt="">
        </div>
        <div class="productDetBox" v-if="$t('language.language') === 'English'">
            <div class="productDetList">
                <p class="productDetTitlBox">產品介紹</p>
                <ul>
                    <li v-for="(item,index) in productInfoListCN" 
                        :key="index"
                        :style="{color: active === index ? '#55B1CD':'#353535'}"
                        @click="SeeDet(item,index)">
                        <i class="el-icon-caret-right"></i>
                        {{item.name}}</li>
                </ul>
            </div>
            <div class="productDetInfo">
                <p class="productDetTitlBox">{{title}}</p>
                <div v-html="ProductDetObj[this.CurrtValue]"></div>
            </div>
        </div>
        <div class="productDetBox" v-if="$t('language.language') === 'Chinese'">
           <div class="productDetList">
                <p class="productDetTitlBox">Product Introduction</p>
                <ul>
                    <li v-for="(item,index) in productInfoListEN" 
                        :key="index"
                        :style="{color: active === index ? '#55B1CD':'#353535'}"
                        @click="SeeDet(item,index)">
                        <i class="el-icon-caret-right"></i>
                        {{item.name}}</li>
                </ul>
            </div>
              <div class="productDetInfo">
                <p class="productDetTitlBox">{{title}}</p>
                <div v-html="ProductDetEN[this.CurrtValue]"></div>
            </div>
        </div>
    </div>
</template>
<script>
import { ProductDetObj } from './PCProductDetObj.js';
export default {
    name:"PCproductDet",
    data(){
        return{
            ProductDetObj,
            title:'test',
            active:this.$route.query.index,
            CurrtValue:'',
            productInfoListCN:[
                {name:'Ablaze全瓷系統',value:'productTypeCNimg1_2'},
                {name:'運動護齒套',value:'productTypeCNimg4_5'},
                {name:'护磨牙墊',value:'productTypeCNimg4_1'},//2
                {name:'防鼾器',value:'productTypeCNimg4_2'},//3
                {name:'法蘭克爾矯正器',value:'productTypeCNimg4_3'},//4
                {name:'螺旋矯正器',value:'productTypeCNimg4_6'},//5
                {name:'哈利氏保持器',value:'productTypeCNimg4_4'},//6
                {name:'Snap on smile',value:'productTypeCNimg5_6'},//7
                {name:'聚醚醚酮peek支架',value:'productTypeCNimg5_4'},//8
                {name:'全口義齒',value:'productTypeCNimg5_5'},//9
                {name:'德國BPD完美支架',value:'productTypeCNimg5_1'},//10
                {name:'維他靈支架',value:'productTypeCNimg5_2'},//11
                {name:'純鈦鋼托支架',value:'productTypeCNimg5_3'},//12
                {name:'馬里蘭橋',value:'productTypeCNimg2_5'},//13
                {name:'金屬烤瓷冠',value:'productTypeCNimg2_4'},//14
                {name:'生物鐳射烤瓷',value:'productTypeCNimg2_1'},//15
                {name:'LAVA全瓷系统 / Lava Full Ceramic',value:'productTypeCNimg1_6'},//16
                {name:'易美全瓷系統',value:'productTypeCNimg1_5'},//17
                {name:'威兰德全瓷系統/ WIELAND',value:'productTypeCNimg1_3'}, //18
                {name:'金屬嵌體 / METAL INLAY',value:'productTypeCNimg2_2'},
                {name:'種植个性化基台',value:'productTypeCNimg3_3'},
                {name:'種植導板',value:'productTypeCNimg3_2'}, //21
                {name:'種植覆蓋義齒',value:'productTypeCNimg3_1'},
                {name:'鑄造個性化種植支架',value:'productTypeCNimg3_4'},//23
                {name:'精密附件套筒冠',value:'productAnnexObj3'},//24
                {name:'精密附件快模式( Snap)',value:'productAnnexObj1'},//25
                {name:'精密附件栓道式 （Preci-line）',value:'productAnnexObj2'},//26/
                {name:'精密附件根帽附著體（scop）',value:'productAnnexObj4'},//27
                {name:'精密附件杆卡附著體（BAR）',value:'productAnnexObj6'},//28

            ],
            productInfoListEN:[
                {name:'Cast personalized planting bracket',value:'productTypeENimg1'},
                {name:'Implant an overdenture',value:'productTypeENimg2'},
                {name:'Planting guide',value:'productTypeENimg3'},
                {name:'Grow personalized abutments',value:'productTypeENimg4'},
                {name:'METAL inlays',value:'productTypeENimg5'},
                {name:'WIELAND all-porcelain System/WIELAND',value:'productTypeENimg6'},
                {name:'History of e-Mei all-Ceramic System/E.AX Full Ceramic',value:'productTypeENimg7'},
                {name:'Lava Full Ceramic system',value:'productTypeENimg8'},
                {name:'PORCELAIN FUSED METAL CROWN/PORCELAIN FUSED METAL CROWN',value:'productTypeENimg9'},
                {name:'Biological laser porcelain',value:'productTypeENimg10'},
                {name:'Mali lanqiao',value:'productTypeENimg11'},
                {name:'Pure titanium steel bracket',value:'productTypeENimg12'},
                {name:'Vitalin Stent/Vitallium Denture System',value:'productTypeENimg13'},
                {name:'Germany BPD Perfect stent/Bego Perfect Denture',value:'productTypeENimg14'},
                {name:'denture',value:'productTypeENimg15'},
                {name:'Polyether ether ketone peek stent',value:'productTypeENimg16'},
                {name:'Snap on smile',value:'productTypeENimg17'},
                {name:'Hawley retainer.',value:'productTypeENimg18'},
                {name:'Expansion screw Appliance (3-direction Screw).',value:'productTypeENimg19'},
                {name:'Frankel Correction Tofrankel Appliance.',value:'productTypeENimg20'},
                {name:'Night guards come in three types',value:'productTypeENimg21'},
                {name:'Snoring Anti-snoring Device. Anti-snoring device.',value:'productTypeENimg22'},
                {name:'Ablaze all-porcelain system',value:'productTypeENimg23'},

            ],
            ProductDetEN:{        
                    productTypeENimg1:
                            "<p class='PcDetCenterFoot'>【Product Name】</P>"+
                            "<p class='PcDetCenterFoot'>Cast personalized planting bracket </p>"+
                            "<p class='PcDetCenterFoot'>【Product Features】</p>"+
                            "<p class='PcDetCenterFoot'>1、The personalized bracket and the finished abutment are cast together, and the plastic or wax type is connected on the abutment or abutment type, and then the upper repair is carried out on the bracket after the embedding and wax casting and polishing is completed. The bracket is connected with the implant in the lower part of the mouth through screws;</p>"+
                            "<p class='PcDetCenterFoot'>2、The metal bracket can increase the strength, retainer and dispersion force of the superstructure;</p>"+
                            "<p class='PcDetCenterFoot'>3、more ideal aesthetic and functional effect;</p>"+
                            "<p class='PcDetCenterFoot'>4、patients can later maintain and maintain their own. </p>"+
                            "<p class='PcDetCenterFoot'>【Product Standard】</P>"+
                            "<p class='PcDetCenterFoot'>1、Personalized scaffold and finished abutment are cast together. Abutment teeth or abutment replacement shapes are cast on the scaffold, and then upper restoration is carried out on it. The doctor comes to the upper finished abutment;</P>"+
                            "<p class='PcDetCenterFoot'>2.Matching connection screws;</P>"+
                            "<p class='PcDetCenterFoot'>3.The support material is the same as the metal type of the finished abutment. If the abutment is plastic and castable, any metal can be selected.</P>"+
                            "<p class='PcDetCenterFoot'>4.the upper restoration can choose direct porcelain or plastic repair, crown repair or artificial tooth repair.</P>"+
                            "<p class='PcDetCenterFoot'>【Technological process】</P>"+
                            "<p class='PcDetCenterFoot'>Design stent → casting stent → clinical trial stent → completion of upper repair.</P>"+
                            "<img class='PcDetimgBox' src="+require('../../assets/PCimg/PcproductDetimgs/PCproductImgEN1.png')+">",
                    productTypeENimg2:
                                "<p class='PcDetCenterFoot'>【Product Name】</P>"+
                                "<p class='PcDetCenterFoot'>Implant an overdenture</p>"+
                                "<p class='PcDetCenterFoot'>[Scope of application]</p>"+
                                "<p class='PcDetCenterFoot'>[Scope of application]</p>"+
                                "<p class='PcDetCenterFoot'>【Product Features】</p>"+
                                "<p class='PcDetCenterFoot'>1. Better retention and stability, because of the auxiliary retention force provided by the implant, the retention stability effect is better</p>"+
                                "<p class='PcDetCenterFoot'>2, higher chewing efficiency, because the retention stability effect is good, eating will not loosen, there is no embarrassment of stuffing food; </p>"+
                                "<p class='PcDetCenterFoot'>3, higher comfort and satisfaction, because the kitt and edge can be made shorter, or even no kitt, less foreign body sensation in the mouth; </p>"+
                                "<p class='PcDetCenterFoot'>4. Implant implantation preserves the surrounding alveolar bone and slows down the resorption of alveolar bone. </p>"+
                                "<p class='PcDetCenterFoot'>【Product Standard】</P>"+
                                "<p class='PcDetCenterFoot'>1. At least two implants provide retainer and have a good common emplacement pathway.</P>"+
                                "<p class='PcDetCenterFoot'>2, You can choose rod type, ball cap, magnetic attachment and double crown retainer. </P>"+
                                "<img class='PcDetimgBox' src="+require('../../assets/PCimg/PcproductDetimgs/PCproductImgEN2.png')+">",
                                // "<img class='PcDetimgBox' src="+require('../../assets/PCimg/PcproductDetimgs/PCproductImgEN3.png')+">",
                        productTypeENimg3:
                                "<p class='PcDetCenterFoot'>【Product Name】</P>"+
                                "<p class='PcDetCenterFoot'>Planting guide</p>"+
                                "<p class='PcDetCenterFoot'>[Scope of application]</p>"+
                                "<p class='PcDetCenterFoot'>Restorative treatment of edentulous patients</p>"+
                                "<p class='PcDetCenterFoot'>【Product Solutions】</p>"+
                                "<p class='PcDetCenterFoot'>1.By providing CBCT data and intraoral data, the doctor can complete the design of 3D guide plate and reduce the risk of surgery;</p>"+
                                "<p class='PcDetCenterFoot'>2.Using 3D implant guide plate technology, the system will automatically generate the corresponding abutment model database when the simulated implantation is completed, providing a basis for the post-production of personalized abutment.</p>"+
                                "<p class='PcDetCenterFoot'>【Product Features】</P>"+
                                "<p class='PcDetCenterFoot'>1.The combination of simulated implant implantation effect and later restoration effect can improve the overall restoration effect of implanted teeth</P>"+
                                "<p class='PcDetCenterFoot'>2.The provision of surgical instruments can complete the implantation of implants and improve the accuracy of the whole operation;</P>"+
                                "<p class='PcDetCenterFoot'>3.After the design is completed, an electronic surgery plan can be provided to the doctor, which records the basic information of the implant and all the conditions in the alveolar bone of the implant. After the doctor confirms the completion, the 3D guide plate can be processed.</P>"+
                                "<p class='PcDetCenterFoot'>4.It can also provide the designed data surgery plan to the doctor, allowing the doctor to change the implant location information, which greatly improves the efficiency of the electronic surgery plan in medical communication.</P>"+
                                "<img class='PcDetimgBox' src="+require('../../assets/PCimg/PcproductDetimgs/PCproductImgEN4.png')+">",
                                // "<img class='PcDetimgBox' src="+require('../../assets/PCimg/PcproductDetimgs/PCproductImgEN5.png')+">",
                        productTypeENimg4:
                                "<p class='PcDetCenterFoot'>【Product Name】</P>"+
                                "<p class='PcDetCenterFoot'>Grow personalized abutments</p>"+
                                "<p class='PcDetCenterFoot'>[Scope of application]</p>"+
                                "<p class='PcDetCenterFoot'>Restorative treatment of edentulous patients</p>"+
                                "<p class='PcDetCenterFoot'>There are 258 implant systems to choose from to meet all patient needs</p>"+
                                "<p class='PcDetCenterFoot'>【Product Features】</P>"+
                                "<p class='PcDetCenterFoot'>1. Natural anatomical surface and fit degree</P>"+
                                "<p class='PcDetCenterFoot'>2.to meet the needs of various gum height and super-large Angle</P>"+
                                "<p class='PcDetCenterFoot'>3. Avoid all kinds of troubles for clinicians to choose abutment</P>"+
                                "<p class='PcDetCenterFoot'>4.make complex planting simple, low cost, low risk</P>"+
                                "<p class='PcDetCenterFoot'>【Product Solutions】</p>"+
                                "<p class='PcDetCenterFoot'>1. At least two implants provide retainer and have a good common emplacement pathway.</p>"+
                                "<p class='PcDetCenterFoot'>2, You can choose rod type, ball cap, magnetic attachment and double crown retainer. </p>"+
                                // "<img class='PcDetimgBox' src="+require('../../assets/PCimg/PcproductDetimgs/PCproductImgEN6.png')+">"+
                                "<img class='PcDetimgBox' src="+require('../../assets/PCimg/PcproductDetimgs/PCproductImgEN7.png')+">"+
                                "<img class='PcDetimgBox' src="+require('../../assets/PCimg/PcproductDetimgs/PCproductImgEN8.png')+">",
                        productTypeENimg5:
                                "<p class='PcDetCenterFoot'>【Product Name】</P>"+
                                "<p class='PcDetCenterFoot'>METAL inlays</p>"+
                                "<p class='PcDetCenterFoot'>[Scope of application]</P>"+
                                "<p class='PcDetCenterFoot'>It is suitable for the restoration of healthy teeth with large size, although the tooth is defective.</p>"+
                                "<p class='PcDetCenterFoot'>【Product Features】</p>"+
                                "<p class='PcDetCenterFoot'>1.Compared with intraoral filling, inlay is more ideal in shape and function recovery, and highly polished, not easy to attach plaque, easy to clean, strength and durability are also better.</p>"+
                                "<p class='PcDetCenterFoot'>2.Gold alloy inlay has stable chemical properties, good ductility and mechanical properties, and is an ideal traditional restorative material for the production of posterior dental inlay.</p>"+
                                "<p class='PcDetCenterFoot'>3, but the aesthetics is poor. </p>"+
                                "<p class='PcDetCenterFoot'>【Product Standard】</P>"+
                                "<p class='PcDetCenterFoot'>1. All-metal inlay;</P>"+
                                "<p class='PcDetCenterFoot'>2.can choose nickel chromium alloy, cobalt chromium alloy, pure titanium and all kinds of precious metals.</P>"+
                                "<img class='PcDetimgBox' src="+require('../../assets/PCimg/PcproductDetimgs/PCproductImgEN9.png')+">",
                        productTypeENimg6:
                                "<p class='PcDetCenterFoot'>【Product Name】</P>"+
                                "<p class='PcDetCenterFoot'>WIELAND all-porcelain System/WIELAND</p>"+
                                "<p class='PcDetCenterFoot'>[Scope of application]</P>"+
                                "<p class='PcDetCenterFoot'>1.Single crown; Long bridge (allow 48mm length of bridge body, generally can have 6 teeth)</p>"+
                                "<p class='PcDetCenterFoot'>2.single inlaid, can also do inlaid bridge, all zirconium crown, bridge</p>"+
                                "<p class='PcDetCenterFoot'>【Product Features】</p>"+
                                "<p class='PcDetCenterFoot'>1.bending strength up to 1400MPa</p>"+
                                "<p class='PcDetCenterFoot'>2.high purity raw materials with excellent permeability, the use of liquid isostatic pressing patent technology processing with safe, reliable, stable biocompatibility; At the same time, the inner crown coating technology is used to meet the needs of different inner crown colors. </p>"+
                                "<p class='PcDetCenterFoot'>【Product Standard】</P>"+
                                "<p class='PcDetCenterFoot'>Crown material: Zirconia Crown thickness: 0.4-0.7MM Crown strength: 1440MP</P>"+
                                "<img class='PcDetimgBox' src="+require('../../assets/PCimg/PcproductDetimgs/PCproductImgEN10.png')+">",
                        productTypeENimg7:
                                "<p class='PcDetCenterFoot'>【Product Name】</P>"+
                                "<p class='PcDetCenterFoot'>History of e-Mei all-Ceramic System/E.AX Full Ceramic</p>"+
                                "<p class='PcDetCenterFoot'>[Scope of application]</P>"+
                                "<p class='PcDetCenterFoot'>1.Single crown; There were 3 internal Bridges, which were limited to the premolar, and only one was missing, which could not be used as a cantilever bridge.</p>"+
                                "<p class='PcDetCenterFoot'>2.veneer: traditional thickness: 0.6mm ultrathin veneer thinnest to 0.2mm inlays, post core</p>"+
                                "<p class='PcDetCenterFoot'>【Product Features】</p>"+
                                "<p class='PcDetCenterFoot'>1.Glass ceramic bottom crown material</p>"+
                                "<p class='PcDetCenterFoot'>2.Highly aesthetic and optical effects, preferred for aesthetic restoration </p>"+
                                "<p class='PcDetCenterFoot'>3.Excellent bonding effect, no negative effect on the human body, perfect integration with natural teeth.</P>"+
                                "<img class='PcDetimgBox' src="+require('../../assets/PCimg/PcproductDetimgs/PCproductImgEN11.png')+">"+
                                "<img class='PcDetimgBox' src="+require('../../assets/PCimg/PcproductDetimgs/PCproductImgEN12.png')+">",
                        productTypeENimg8:
                                "<p class='PcDetCenterFoot'>【Product Name】</P>"+
                                "<p class='PcDetCenterFoot'>Lava Full Ceramic system</p>"+
                                "<p class='PcDetCenterFoot'>[Scope of application]</P>"+
                                "<p class='PcDetCenterFoot'>1.Single crown; Long bridge (allow 48mm length of bridge body, generally can have 6 teeth)</p>"+
                                "<p class='PcDetCenterFoot'>2.single inlaid, can also do inlaid bridge, all zirconium crown, bridge</p>"+
                                "<p class='PcDetCenterFoot'>【Product Features】</p>"+
                                "<p class='PcDetCenterFoot'>1.It has become a miracle in the industry with ultra-high strength, perfect edge tightness and unparalleled aesthetic effect, benefiting patients worldwide.</p>"+
                                "<p class='PcDetCenterFoot'>2.Class 8 inner crown color, patented dyeing technology, perfect color presentation.</p>"+
                                "<p class='PcDetCenterFoot'>3.3.1440MPA high strength, perfect permeability.</P>"+
                                "<p class='PcDetCenterFoot'>4.The thinnest inner crown of anterior teeth is 0.3MM, matching precision cutting, high precision.</P>"+
                                "<p class='PcDetCenterFoot'>【Product Standard】</P>"+
                                "<p class='PcDetCenterFoot'>Crown material: Zirconia Crown thickness: 0.4-0.7MM Crown strength: 1440MP</p>"+
                                "<p class='PcDetCenterFoot'>【 Clinical Advice】</P>"+
                                "<p class='PcDetCenterFoot'>*Standard for normal preparation of all-porcelain teeth;</p>"+
                                "<p class='PcDetCenterFoot'>*The mold edge line is clearly visible;</p>"+
                                "<p class='PcDetCenterFoot'>*It is recommended to use resin adhesive bonding;</p>"+
                                "<p class='PcDetCenterFoot'>*If it is clinically necessary to adjust the grinding of the restoration, please use fine emery needle to perform point grinding, and under sufficient water cooling, and finally polishing and glazing treatment.</p>"+
                                "<img class='PcDetimgBox' src="+require('../../assets/PCimg/PcproductDetimgs/PCproductImgEN13.png')+">"+
                                "<img class='PcDetimgBox' src="+require('../../assets/PCimg/PcproductDetimgs/PCproductImgEN14.png')+">",
                        productTypeENimg9:
                                "<p class='PcDetCenterFoot'>【Product Name】</P>"+
                                "<p class='PcDetCenterFoot'>PORCELAIN FUSED METAL CROWN/PORCELAIN FUSED METAL CROWN</p>"+
                                "<p class='PcDetCenterFoot'>【Product Features】</p>"+
                                "<p class='PcDetCenterFoot'>1.Traditional metal casting technology is used to make the inner crown. 2, high accuracy, the bottom crown is clean and dense, more conducive to oral safety and health.</p>"+
                                "<p class='PcDetCenterFoot'>【Product Standard】</P>"+
                                "<p class='PcDetCenterFoot'>1.Metal inner crown + porcelain layer sintered;</p>"+
                                "<p class='PcDetCenterFoot'>2.Porcelain veneer on the lip and buccal edge of porcelain crown, and a 2-3mmwide fine metal edge on the tongue side;</p>"+
                                "<p class='PcDetCenterFoot'>3.can choose ordinary cobalt chromium alloy, precious metal, pure titanium and other metal inner crown materials;</p>"+
                                "<p class='PcDetCenterFoot'>4.can also choose all-covered porcelain, all-metal edge or porcelain shoulder crown design.</p>"+
                                "<img class='PcDetimgBox' src="+require('../../assets/PCimg/PcproductDetimgs/PCproductImgEN15.png')+">"+
                                "<img class='PcDetimgBox' src="+require('../../assets/PCimg/PcproductDetimgs/PCproductImgEN16.png')+">",
                        productTypeENimg10:
                                "<p class='PcDetCenterFoot'>【Product Name】</P>"+
                                "<p class='PcDetCenterFoot'>Biological laser porcelain</p>"+
                                "<p class='PcDetCenterFoot'>【Brand Introduction】</P>"+
                                "<p class='PcDetCenterFoot'>Using selective laser melting, is a cutting-edge computer (CAD/CAM technology) technology through CAM computer control laser, selective layer upon layer accumulation of molten metal powder, directly forming metal bottom crown, and then baking porcelain.</p>"+
                                "<p class='PcDetCenterFoot'>【Product Features】</p>"+
                                "<p class='PcDetCenterFoot'>1.not the traditional metal casting technology to make the inner crown. 2, high accuracy, the bottom crown is clean and dense, more conducive to oral safety and health.</p>"+
                                "<p class='PcDetCenterFoot'>【Product Standard】</P>"+
                                "<p class='PcDetCenterFoot'>1.Cobalt-chromium alloy inner crown + porcelain layer;</p>"+
                                "<p class='PcDetCenterFoot'>2.The thickness of the metal inner shell is 0.3MM;</p>"+
                                "<p class='PcDetCenterFoot'>3.Porcelain veneer on the lip and buccal edge of porcelain crown, and a 2-3MMwide fine metal edge on the tongue side;</p>"+
                                "<p class='PcDetCenterFoot'>4.can also choose all-covered porcelain, all metal foot edge or porcelain shoulder design.</p>"+
                                "<img class='PcDetimgBox' src="+require('../../assets/PCimg/PcproductDetimgs/PCproductImgEN17.png')+">"+
                                "<img class='PcDetimgBox' src="+require('../../assets/PCimg/PcproductDetimgs/PCproductImgEN18.png')+">",
                        productTypeENimg11:
                                "<p class='PcDetCenterFoot'>【Product Name】</P>"+
                                "<p class='PcDetCenterFoot'>Mali lanqiao</p>"+
                                "<p class='PcDetCenterFoot'>【Brand Introduction】</P>"+
                                "<p class='PcDetCenterFoot'>Resin bonded fixed partial dentures is a kind of fixed restorations that do not wear or less healthy adjacent teeth and repair individual missing teeth with acid-bonded technology. The development of bond bridge is along with the development of bond technology and repair materials.</p>"+
                                "<p class='PcDetCenterFoot'>【Product Features】</p>"+
                                "<p class='PcDetCenterFoot'>1.Maryland Bridge restoration requires almost no natural tooth extraction. It consists of two metal sheets or HPP sheets attached to the dentures, so the healthy teeth around the missing teeth are not worn away. (unlike baked/all-porcelain teeth where healthy teeth on both sides are worn away);</p>"+
                                "<p class='PcDetCenterFoot'>2.Maryland Bridge These sheets are glued to the back of the tooth in the area adjacent to the missing tooth using adhesive, which requires no surgery and is almost painless;</p>"+
                                "<p class='PcDetCenterFoot'>3.The price of Maryland bridge is also lower than that of dental implants.</p>"+
                                "<p class='PcDetCenterFoot'>4.relatively low price, long service life.</p>"+
                                "<img class='PcDetimgBox' src="+require('../../assets/PCimg/PcproductDetimgs/PCproductImgEN19.png')+">"+
                                "<img class='PcDetimgBox' src="+require('../../assets/PCimg/PcproductDetimgs/PCproductImgEN20.png')+">",
                        productTypeENimg12:
                                "<p class='PcDetCenterFoot'>【Product Name】</P>"+
                                "<p class='PcDetCenterFoot'>Pure titanium steel bracket</p>"+
                                "<p class='PcDetCenterFoot'>【Brand Introduction】</P>"+
                                "<p class='PcDetCenterFoot'>Imported pure titanium, made in Japan.</p>"+
                                "<p class='PcDetCenterFoot'>[Scope of application]</p>"+
                                "<p class='PcDetCenterFoot'>Scaffolds for mobile repair of dentition defects.</p>"+
                                "<p class='PcDetCenterFoot'>【Product Features】</p>"+
                                "<p class='PcDetCenterFoot'>It has superior biocompatibility, elasticity, lightness and other characteristics.</p>"+
                                "<p class='PcDetCenterFoot'>【 Product Standard 】</p>"+
                                "<p class='PcDetCenterFoot'>Pure titanium steel bracket, including retainer clasp, jaw brace, and anterior retainer nail cast with the whole bracket.</p>"+
                                "<img class='PcDetimgBox' src="+require('../../assets/PCimg/PcproductDetimgs/PCproductImgEN21.png')+">"+
                                "<img class='PcDetimgBox' src="+require('../../assets/PCimg/PcproductDetimgs/PCproductImgEN22.png')+">",
                        productTypeENimg13:
                                "<p class='PcDetCenterFoot'>【Product Name】</P>"+
                                "<p class='PcDetCenterFoot'>Vitalin Stent/Vitallium Denture System</p>"+
                                "<p class='PcDetCenterFoot'>【Brand Introduction】</P>"+
                                "<p class='PcDetCenterFoot'>The world's top 500 enterprises, the United States Dunsberg company of high quality metal scaffold materials.</p>"+
                                "<p class='PcDetCenterFoot'>[Scope of application]</p>"+
                                "<p class='PcDetCenterFoot'>Scaffolds for mobile repair of dentition defects.</p>"+
                                "<p class='PcDetCenterFoot'>【Product Features】</p>"+
                                "<p class='PcDetCenterFoot'>1.excellent physical performance and operational performance;</p>"+
                                "<p class='PcDetCenterFoot'>2.It can be used in the design of removable partial denture scaffold for mucosal bearing stress interruption and stress release;</p>"+
                                "<p class='PcDetCenterFoot'>3.It provides a better solution for the treatment of free end deletion.</p>"+
                                "<p class='PcDetCenterFoot'>【 Product Standard 】</p>"+
                                "<p class='PcDetCenterFoot'>* Vitalin support, including retainer clasp, jaw brace, and retainer nail in the anterior dental area cast integral with the support.</p>"+
                                "<img class='PcDetimgBox' src="+require('../../assets/PCimg/PcproductDetimgs/PCproductImgEN23.png')+">"+
                                "<img class='PcDetimgBox' src="+require('../../assets/PCimg/PcproductDetimgs/PCproductImgEN24.png')+">",
                        productTypeENimg14:
                                "<p class='PcDetCenterFoot'>【Product Name】</P>"+
                                "<p class='PcDetCenterFoot'>Germany BPD Perfect stent/Bego Perfect Denture</p>"+
                                "<p class='PcDetCenterFoot'>【Brand Introduction】</P>"+
                                "<p class='PcDetCenterFoot'>Germany BPD Perfect stent/Bego Perfect Denture</p>"+
                                "<p class='PcDetCenterFoot'>[Scope of application]</p>"+
                                "<p class='PcDetCenterFoot'>Scaffolds for mobile repair of dentition defects.</p>"+
                                "<p class='PcDetCenterFoot'>【Product Features】</p>"+
                                "<p class='PcDetCenterFoot'>The bracket has superior elasticity, close, thin, lightweight and other characteristics.</p>"+
                                "<p class='PcDetCenterFoot'>【 Product Standard 】</p>"+
                                "<p class='PcDetCenterFoot'>BPD perfect bracket, including retentive clasp, jaw brace, and anterior retentive nail cast integral with the bracket.</p>"+
                                "<img class='PcDetimgBox' src="+require('../../assets/PCimg/PcproductDetimgs/PCproductImgEN25.png')+">"+
                                "<img class='PcDetimgBox' src="+require('../../assets/PCimg/PcproductDetimgs/PCproductImgEN26.png')+">",
                        productTypeENimg15:
                                "<p class='PcDetCenterFoot'>【Product Name】</P>"+
                                "<p class='PcDetCenterFoot'>denture</p>"+
                                "<p class='PcDetCenterFoot'>[Scope of application]</p>"+
                                "<p class='PcDetCenterFoot'>It is suitable for the restoration of complete dentition loss, which refers to the conventional complete denture supported by mucosa and subdiaphragm tissue.</p>"+
                                "<p class='PcDetCenterFoot'>【Product Features】</p>"+
                                "<p class='PcDetCenterFoot'>Removable denture, beautiful, lightweight.</p>"+
                                "<p class='PcDetCenterFoot'>【 Product Standard 】</p>"+
                                "<p class='PcDetCenterFoot'>Ordinary plastic base + ordinary resin teeth (Kaifeng gum teeth).</p>"+
                                "<p class='PcDetCenterFoot'>【Clinical Advice】</p>"+
                                "<p class='PcDetCenterFoot'>1.It is suggested to make individual trays first so as to obtain accurate impressions.</p>"+
                                "<p class='PcDetCenterFoot'>2.High-end base materials such as 199 unbroken glue and high-end resin teeth can be selected, but additional charges are required.</p>"+
                                "<img class='PcDetimgBox' src="+require('../../assets/PCimg/PcproductDetimgs/PCproductImgEN27.png')+">",
                        productTypeENimg16:
                                "<p class='PcDetCenterFoot'>【Product Name】</P>"+
                                "<p class='PcDetCenterFoot'>Polyether ether ketone peek stent</p>"+
                                "<p class='PcDetCenterFoot'>[Scope of application]</p>"+
                                "<p class='PcDetCenterFoot'>1.removable denture, beautiful, lightweight</p>"+
                                "<p class='PcDetCenterFoot'>2.low density, light quality, relatively small foreign body sensation</p>"+
                                "<p class='PcDetCenterFoot'>3.Removable denture, upper part of implant, sleeve crown, rod clamp structure restoration, etc</p>"+
                                "<p class='PcDetCenterFoot'>【Product Features】</p>"+
                                "<p class='PcDetCenterFoot'>Removable denture, beautiful, lightweight.</p>"+
                                "<p class='PcDetCenterFoot'>【 Product Standard 】</p>"+
                                "<p class='PcDetCenterFoot'>Ordinary plastic base + ordinary resin teeth (Kaifeng gum teeth).</p>"+
                                "<p class='PcDetCenterFoot'>【Clinical Advice】</p>"+
                                "<p class='PcDetCenterFoot'>1.the main material: easy to process, good corrosion resistance, light than metal, pure Peek biocompatibility, etc.;</p>"+
                                "<p class='PcDetCenterFoot'>2.physiological characteristics: elastic, shock absorption, no wear on the remaining teeth, elastic as jaw · fracture and torsion resistance, inclusiveness, wear resistance;</p>"+
                                "<p class='PcDetCenterFoot'>3.biocompatibility: metal free, allergic, insoluble in water, anti-bacterial spots, anti-conductive, stable chemical properties, compared with ceramic can maintain inherent strength, no aging deterioration or degradation, good X-ray performance, convenient detection of postoperative recovery;</p>"+
                                "<p class='PcDetCenterFoot'>4.high patient satisfaction: · Natural beauty, natural chewing ability, cost-effective, nonstaining, easy and natural feeling in the mouth · More pleasant than rigid repair 5. Processing advantages: easy grinding, polishing, easy veneer;</p>"+
                                "<img class='PcDetimgBox' src="+require('../../assets/PCimg/PcproductDetimgs/PCproductImgEN28.png')+">"+
                                "<img class='PcDetimgBox' src="+require('../../assets/PCimg/PcproductDetimgs/PCproductImgEN29.png')+">",
                        productTypeENimg17:
                                "<p class='PcDetCenterFoot'>【Product Name】</P>"+
                                "<p class='PcDetCenterFoot'>Snap on smile</p>"+
                                "<p class='PcDetCenterFoot'>[Scope of application]</p>"+
                                "<p class='PcDetCenterFoot'>Tetracycline teeth, local absence, irregular dentition</p>"+
                                "<p class='PcDetCenterFoot'>【Product Features】</p>"+
                                "<p class='PcDetCenterFoot'>1.removable denture, beautiful, lightweight. </p>"+
                                "<p class='PcDetCenterFoot'>2.No spare teeth</p>"+
                                "<img class='PcDetimgBox' src="+require('../../assets/PCimg/PcproductDetimgs/PCproductImgEN30.png')+">"+
                                "<img class='PcDetimgBox' src="+require('../../assets/PCimg/PcproductDetimgs/PCproductImgEN31.png')+">",
                        productTypeENimg18:
                                "<p class='PcDetCenterFoot'>【Product Name】</P>"+
                                "<p class='PcDetCenterFoot'>Hawley retainer.</p>"+
                                "<p class='PcDetCenterFoot'>[Scope of application]</p>"+
                                "<p class='PcDetCenterFoot'>In order to maintain the clinical effect of the treatment, the teeth can be worn after the treatment to make a small amount of movement, or by adjusting the lip arch to close a small amount of space in the anterior teeth.</p>"+
                                "<p class='PcDetCenterFoot'>【Product Features】</p>"+
                                "<p class='PcDetCenterFoot'>It is a commonly used standard mobile appliance, which can be removed and worn by the patient.</p>"+
                                "<p class='PcDetCenterFoot'>【 Product Standard 】</p>"+
                                "<p class='PcDetCenterFoot'>Consists of 1 lip arch + 2 arrowhead clasp + 1 piece of clean color resin glue base plate</p>"+
                                "<p class='PcDetCenterFoot'>【Clinical Advice】</p>"+
                                "<p class='PcDetCenterFoot'>1.Harry's retainer can be worn for a long time in addition to brushing teeth. In order to stabilize and maintain the effect, it should be worn for a long time.</p>"+
                                "<p class='PcDetCenterFoot'>2.If you want to strengthen the maintenance effect, you can stick the lip arch to the tooth;</p>"+
                                "<p class='PcDetCenterFoot'>3.The specific application should be determined by the doctor according to the clinical situation. This suggestion is for reference only.</p>"+
                                "<img class='PcDetimgBox' src="+require('../../assets/PCimg/PcproductDetimgs/PCproductImgEN32.png')+">"+
                                "<img class='PcDetimgBox' src="+require('../../assets/PCimg/PcproductDetimgs/PCproductImgEN33.png')+">",
                        productTypeENimg19:
                                "<p class='PcDetCenterFoot'>【Product Name】</P>"+
                                "<p class='PcDetCenterFoot'>Expansion screw Appliance (3-direction Screw).</p>"+
                                "<p class='PcDetCenterFoot'>[Scope of application]</p>"+
                                "<p class='PcDetCenterFoot'>When the dental arch is crowded, expand the dental arch.</p>"+
                                "<p class='PcDetCenterFoot'>【Product Features】</p>"+
                                "<p class='PcDetCenterFoot'>1.mainly through the helical force expansion bow</p>"+
                                "<p class='PcDetCenterFoot'>2.Mobile appliance, doctors or patients can remove and wear it by themselves</p>"+
                                "<p class='PcDetCenterFoot'>【 Product Standard 】</p>"+
                                "<p class='PcDetCenterFoot'> arrowhead clasp retainer +1 piece of clean color resin adhesive base plate +1 3 side screw pusher. </p>"+
                                "<p class='PcDetCenterFoot'>【Clinical Advice】</p>"+
                                "<p class='PcDetCenterFoot'>1.When adding force, it should be added gradually.</p>"+
                                "<p class='PcDetCenterFoot'>2.After the expansion of the dental arch, align the teeth with other orthotics.</p>"+
                                "<p class='PcDetCenterFoot'>3.In addition to 2 arrow cards, base and 1 3-face push screw device, additional charges are required for the design of other accessories.</p>"+
                                "<p class='PcDetCenterFoot'>4.The specific application should be determined by the doctor according to the clinical situation. This suggestion is for reference only.</p>"+
                                "<img class='PcDetimgBox' src="+require('../../assets/PCimg/PcproductDetimgs/PCproductImgEN34.png')+">",
                        productTypeENimg20:
                                "<p class='PcDetCenterFoot'>【Product Name】</P>"+
                                "<p class='PcDetCenterFoot'> Frankel Correction Tofrankel Appliance.</p>"+
                                "<p class='PcDetCenterFoot'>[Scope of application]</p>"+
                                "<p class='PcDetCenterFoot'>1.Type I correction Ann's class I and class II 1 classification misfit functional orthotics;</p>"+
                                "<p class='PcDetCenterFoot'>2.Type II correction Ann's Class II, 2 misaligned functional orthotics;</p>"+
                                "<p class='PcDetCenterFoot'>3.Type III functional corrector for Ann's class III misfit Type;</p>"+
                                "<p class='PcDetCenterFoot'>4.and type Ⅳ are mainly used in the denture stage and the early permanent teeth with arch stenosis, basal bone insufficiency and mild osseous opening and closing deformity.</p>"+
                                "<p class='PcDetCenterFoot'>【 Product Standard 】</p>"+
                                "<p class='PcDetCenterFoot'>Lip screen + buccal screen + lip screen + lip arch + palatal arch + combined support.</p>"+
                                "<p class='PcDetCenterFoot'>【Clinical Advice】</p>"+
                                "<p class='PcDetCenterFoot'>1.Class II doctors take records according to the relationship that the mandibular extension does not exceed 2.5-3MM, and the mandibular extension can reach forward to cut to cut if it covers about 4MM, and the vertical opening of the anterior teeth is generally about 2.5MM. If the coverage is too large, can step forward; Class III general anterior teeth are cut to cut relationship, repeated closing, open 2-3 mm.</p>"+
                                "<p class='PcDetCenterFoot'>2.The impression must accurately reflect the morphology of the soft and hard tissues in the mouth, including the whole dentition, alveolar process, the whole vestibular area of the mucosal folds, the labibuccal lingual frenulum and the maxillary nodules.</p>"+
                                "<p class='PcDetCenterFoot'>3.Patients only wear it for 1-3 hours a day at the beginning, and can not use it temporarily at night, and gradually increase if there is no discomfort; Wear the appliance all DAY long, not less than 14 hours a day. When the appliance is not in use, it must be stored in a plastic or metal box to avoid loss or extrusion.</p>"+
                                "<img class='PcDetimgBox' src="+require('../../assets/PCimg/PcproductDetimgs/PCproductImgEN35.png')+">"+
                                "<img class='PcDetimgBox' src="+require('../../assets/PCimg/PcproductDetimgs/PCproductImgEN36.png')+">",
                        productTypeENimg21:
                                "<p class='PcDetCenterFoot'>【Product Name】</P>"+
                                "<p class='PcDetCenterFoot'>Night guards come in three types:</p>"+
                                "<p class='PcDetCenterFoot'>1.Night guard for hard and soft teeth;</p>"+
                                "<p class='PcDetCenterFoot'>2.Formed Night guard (pressure-formed);</p>"+
                                "<p class='PcDetCenterFoot'>3.Formed Night guard (pressure-formed).</p>"+
                                "<p class='PcDetCenterFoot'>[Scope of application]</p>"+
                                "<p class='PcDetCenterFoot'>To prevent night molars, wear it when sleeping. Occlusal soft adhesive is suitable for preventing mild night molars. </p>"+
                                "<p class='PcDetCenterFoot'>【 Product Features 】</p>"+
                                "<p class='PcDetCenterFoot'>1.Occlusal glue: soft glue, comfortable to wear, but not wear-resistant. Color film, beautiful appearance.</p>"+
                                "<p class='PcDetCenterFoot'>2.occlusal hard glue: hard glue, wear comfort is poor, but wear resistance.</p>"+
                                "<p class='PcDetCenterFoot'>3.soft and hard combination: the use of soft outside hard special transparent film, that is to ensure the comfort of wearing, and wear resistance.</p>"+
                                "<p class='PcDetCenterFoot'>【Product Standard 】</p>"+
                                "<p class='PcDetCenterFoot'>1.occlusal glue: use 2-3 mm single color film or three-color mixed color film, with hot pressing technology, soft glue cover to the gingiva;</p>"+
                                "<p class='PcDetCenterFoot'>2.occlusal hard glue: the use of 2-3mm transparent color hard film, with hot pressing plastic technology production, can also be made by hot setting technology. Hard rubber pads cover part of the crown on the lip and cheek, and cover the gingiva on the tongue;</p>"+
                                "<p class='PcDetCenterFoot'>3.soft and hard combination: the use of 2-3MM thick one side is soft glue and the other side is hard glue special transparent film, with hot pressing technology, glue base lip and tongue side are covered to the gingiva. </p>"+
                                "<p class='PcDetCenterFoot'>【Clinical Advice】</p>"+
                                "<p class='PcDetCenterFoot'>1.Wax dikes are required for maxillary model and occlusal relationship;</p>"+
                                "<p class='PcDetCenterFoot'>2.anti-grinding teeth, put on before going to bed. Comfortable and wear-resistant.</p>"+
                                "<img class='PcDetimgBox' src="+require('../../assets/PCimg/PcproductDetimgs/PCproductImgEN37.png')+">"+
                                "<img class='PcDetimgBox' src="+require('../../assets/PCimg/PcproductDetimgs/PCproductImgEN38.png')+">",
                        productTypeENimg22:
                                "<p class='PcDetCenterFoot'>【Product Name】</P>"+
                                "<p class='PcDetCenterFoot'>Snoring Anti-snoring Device. Anti-snoring device.</p>"+
                                "<p class='PcDetCenterFoot'>[Scope of application]</p>"+
                                "<p class='PcDetCenterFoot'>To prevent snoring. By pushing the jaw forward, the wearer can open the airway that is narrowed during sleep, reducing or removing the resonant sound of snoring. </p>"+
                                "<p class='PcDetCenterFoot'>【 Product Standard 】</p>"+
                                "<p class='PcDetCenterFoot'>1.the use of transparent color hard film or soft film, with hot pressing plastic technology production.</p>"+
                                "<p class='PcDetCenterFoot'>2.1 set of 2 pieces imported adjustable rod, adjustable rod fixed on the left and right side of the upper and lower tray.</p>"+
                                "<p class='PcDetCenterFoot'>【Clinical Advice】</p>"+
                                "<p class='PcDetCenterFoot'>At the initial stage of anti-snoring device processing, the mandible moved 2mm forward on the basis of median conjoint to fix the adjustable bar. The doctor changed the length of the adjustable bar according to clinical needs. The shorter the adjustable bar, the farther forward the jaw, the longer the adjustable bar, and the farther back the jaw.Put it on before you go to sleep</p>"+
                                "<img class='PcDetimgBox' src="+require('../../assets/PCimg/PcproductDetimgs/PCproductImgEN39.png')+">"+
                                "<img class='PcDetimgBox' src="+require('../../assets/PCimg/PcproductDetimgs/PCproductImgEN40.png')+">",
                        productTypeENimg23:
                                "<p class='PcDetCenterFoot'>【Product Name】</P>"+
                                "<p class='PcDetCenterFoot'>Ablaze all-porcelain system</p>"+
                                "<p class='PcDetCenterFoot'>[Scope of application]</p>"+
                                "<p class='PcDetCenterFoot'>1.Single crown; Long bridge (allow 48mm length of bridge body, generally can have 6 teeth)</p>"+
                                "<p class='PcDetCenterFoot'>2.single inlaid, can also do inlaid bridge, all zirconium crown, bridge</p>"+
                                "<p class='PcDetCenterFoot'>【 Product Features 】</p>"+
                                "<p class='PcDetCenterFoot'>1.It has become a miracle in the industry with ultra-high strength, perfectedge tightness and unparalleled aesthetic effect, benefiting patients worldwide.</p>"+
                                "<p class='PcDetCenterFoot'>2.Class 8 inner crown color, patented dyeing technology, perfect colorpresentation.</p>"+
                                "<p class='PcDetCenterFoot'>3.1220MPA high strength, perfect permeability.</p>"+
                                "<p class='PcDetCenterFoot'>4.The thinnest inner crown of anterior teeth is 0.3MM, matching precisioncutting, high precision.</p>"+
                                "<p class='PcDetCenterFoot'>【Product Standard 】</p>"+
                                "<p class='PcDetCenterFoot'>Crown material: Zirconia Crown thickness: 0.4-0.7MM Crown strength: 1440MP</p>"+
                                "<p class='PcDetCenterFoot'>【Clinical Advice】</p>"+
                                "<p class='PcDetCenterFoot'>1.Standard for normal preparation of all-porcelain teeth;</p>"+
                                "<p class='PcDetCenterFoot'>2.the mold edge line is clearly visible;</p>"+
                                "<p class='PcDetCenterFoot'>3.it is recommended to use resin adhesive bonding;</p>"+
                                "<p class='PcDetCenterFoot'>4.such as clinical need to repair the grinding, please use fine emery needle point grinding, and in full water cooling, finally polishing glaze treatment.</p>"+
                                "<img class='PcDetimgBox' src="+require('../../assets/PCimg/PcproductDetimgs/PCproductImgEN41.png')+">",
                }
        }
    },
    mounted(){
        if(this.$i18n.locale === 'en') {
            this.SeeDet({value:'productTypeENimg1'},0)
        }else{
            this.SeeDet(this.$route.query,this.$route.query.index)
        }
    },
    methods:{
        SeeDet(item,index){
            this.active = Number(index);
            this.title = item.name;
            this.CurrtValue = item.value;
        }
    }
}
</script>
<style lang="less">
.PCproductDet_box{
    .PCproductDet_header{
        width: 100%;
        height: 364px;
    }
}
.productDetBox{
    display: flex;
    justify-content: space-between;
}
.productDetList{
    width:700px;
    text-align: left;
    border: 1px solid #ccc;
    ul{
        margin-top: 20px;
        li{
            cursor: pointer;
            padding: 10px ;
            font-size: 14px;
            font-weight: bold;
            color: #353535;
            border-bottom: 1px dotted #ccc;
        }
    }
   
}
.productDetTitlBox{
    margin:0;
    color:#444;
    font-size:14px;
    padding: 10px;
    font-weight: bold;
    border-bottom: 2px solid #55B1CD;
}
.productDetInfo{
    width:1200px;
    text-align: left;
    border: 1px solid #ccc;
    padding: 0 20px;
    .PcDetCenterFoot{

    }
    .PcDetimgBox{
        width: 210px;
        height: 110px;
        margin-right:5px;
    }
}
</style>


