import Vue from "vue";
import VueRouter from 'vue-router';
import Router from "vue-router";
import PCHome from "../page/PCProject/home.vue"
import CenterPage from "../page/PCProject/CenterPage.vue"
import PCproductInfo from "../page/PCProject/productInfo.vue"
import PCproductDet from "../page/PCProject/PCproductDet.vue"
import PcContactpage from "../page/PCProject/PcContactpage.vue"
import Pcompanypage from "../page/PCProject/Pcompanypage.vue"
import Pctechnologypage from "../page/PCProject/Pctechnologypage.vue"
// 移动端页面
import MobileHome from "../page/MobileProject/home.vue"
import sectionPage from "../page/MobileProject/sectionPage.vue"
import newTechniquePage from "../page/MobileProject/newTechniquePage.vue"
import productPage from "../page/MobileProject/productPage.vue"
import productDetPage from "../page/MobileProject/productDetPage.vue"
import CompanyPage from "../page/MobileProject/CompanyPage.vue"
import ContactUs from "../page/MobileProject/ContactUs.vue"
import productInfo from "../page/MobileProject/productInfo.vue"
import productAnnex from "../page/MobileProject/productAnnex.vue"
import productAnnexDet from "../page/MobileProject/productAnnexDet.vue"
Vue.use(VueRouter)

const routerPc = [
    {
        path:'/',
        name:'PCHome',
        redirect: '/home',
        component:PCHome,
        children:[
            {
                path: '/home',
                name: 'home',
                component: CenterPage,
            },
            {
                path: '/PCproductInfo',
                name: 'PCproductInfo',
                component: PCproductInfo,
            },
            {
                path: '/PCproductDet',
                name: 'PCproductDet',
                component: PCproductDet,
            },
            {
                path: '/PcContactpage',
                name: 'PcContactpage',
                component: PcContactpage,
            },
            {
                path: '/Pcompanypage',
                name: 'Pcompanypage',
                component: Pcompanypage,
            },
            {
                path: '/Pctechnologypage',
                name: 'Pctechnologypage',
                component: Pctechnologypage,
            },
        ],
    },
    // {
    //     path: '/index',
    //     component:
    //     () =>import('@/views/pc/index'),
    // }
]
const routerM = [
    {
        path: '/',
        name: '_home',
        redirect: '/home',
        component: MobileHome,
        children: [
            {
                path: '/home',
                name: 'home',
                component: sectionPage,
            },
            {
                path: '/newTechniquePage',
                name: 'newTechniquePage',
                component: newTechniquePage,
            },
            {
                path: '/productPage',
                name: 'productPage',
                component: productPage,
            },
            {
                path: '/CompanyPage',
                name: 'CompanyPage',
                component: CompanyPage,
            },
            {
                path: '/ContactUs',
                name: 'ContactUs',
                component: ContactUs,
            },
            {
                path: '/productInfo',
                name: 'productInfo',
                component: productInfo,
            },
            {
                path: '/productAnnex',
                name: 'productAnnex',
                component: productAnnex,
            },
        ]
    },
    {
        path:'/productDetPage',
        name:'productDetPage',
        component:productDetPage,
    },
    {
        path:'/productAnnexDet',
        name:'productAnnexDet',
        component:productAnnexDet,
    },
    // {
    //     path: '/index',
    //     component: () =>
    //         import('@/views/mobiLe/index')
    // }
]
var routes = []
let Adaptive = /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
if (navigator.userAgent.match(Adaptive)) {
    routes = routerM
} else {
    routes =  routerPc 
}
export const router = new Router({
    mode: "history",
    routes
})