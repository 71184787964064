<template>
    <div class="productBox">
        <div class="PCproduct_header">
            <img class="PCproduct_header" src="../../assets/PCimg/PCproductImgs/productBG.jpg" alt="">
        </div>
        <div class="PCproduct_Title">
            <span class="PCproduct_Title1">{{$t('language.language') === 'Chinese'? 'PRODUCT TYPE ' : '產品類別'}}</span>
            <span class="PCproduct_Title2">{{$t('language.language') === 'Chinese'? 'PRODUCT DISPLAY' : '產品展示'}}</span>
        </div>
        <div class="PCproduct_CentersBox">
            <div class="PCproduct_Centers">
                <div class="PCproduct_Centers_left">
                    <p style="font-size:24px;font-weight: 900;color: rgb(82, 111, 150);">{{$t('language.language') === 'Chinese'? 'Full Ceramic' : '全瓷系列'}}</p>
                    <span class="lineBox"></span>
                    <ul>
                        <li v-if="$t('language.language') === 'Chinese'" v-for="(item,index) in productType1EN.names" @click="linkENPage" :key="index">{{item}}</li>
                        <li v-if="$t('language.language') === 'English'" v-for="(item,index) in productType1CN.names" @click="linkPage(item)" :key="index">{{item.name}}</li>
                    </ul>
                </div>
                <div class="PCproduct_Centers_right">
                    <img class="PCproductrightimg" v-for="(item,index) in productType1CN.urls" :src="item" :key="index"/>
                </div>
            </div>
        </div>
        <div class="PCproduct_CentersBox">
            <div class="PCproduct_Centers">
                <div class="PCproduct_Centers_left">
                    <p style="font-size:24px;font-weight: 900;color: rgb(82, 111, 150);"> {{$t('language.language') === 'Chinese'? 'PFM' : '烤瓷系列'}}</p>
                    <span class="lineBox"></span>
                    <ul>
                        <li v-if="$t('language.language') === 'Chinese'" v-for="(item,index) in productType2EN.names" @click="linkENPage" :key="index">{{item}}</li>
                        <li v-if="$t('language.language') === 'English'" v-for="(item,index) in productType2CN.names" @click="linkPage(item)" :key="index">{{item.name}}</li>
                    </ul>
                </div>
                <div class="PCproduct_Centers_right">
                    <img class="PCproductrightimg" v-for="(item,index) in productType2CN.urls" :src="item" :key="index"/>
                </div>
            </div>
        </div>
        <div class="PCproduct_CentersBox">
            <div class="PCproduct_Centers">
                <div class="PCproduct_Centers_left">
                    <p style="font-size:24px;font-weight: 900;color: rgb(82, 111, 150);">{{$t('language.language') === 'Chinese'? 'Implant' : '種植修復'}}</p>
                    <span class="lineBox"></span>
                    <ul>
                        <li v-if="$t('language.language') === 'Chinese'" v-for="(item,index) in productType3EN.names" @click="linkENPage" :key="index">{{item}}</li>
                        <li v-if="$t('language.language') === 'English'" v-for="(item,index) in productType3CN.names" @click="linkPage(item)" :key="index">{{item.name}}</li>
                    </ul>
                </div>
                <div class="PCproduct_Centers_right">
                    <img class="PCproductrightimg" v-for="(item,index) in productType3CN.urls" :src="item" :key="index"/>
                </div>
            </div>
        </div>
        <div class="PCproduct_CentersBox">
            <div class="PCproduct_Centers">
                <div class="PCproduct_Centers_left">
                    <p style="font-size:24px;font-weight: 900;color: rgb(82, 111, 150);">{{$t('language.language') === 'Chinese'? 'Orthodontic Appliance' : '矯正裝置'}}</p>
                    <span class="lineBox"></span>
                    <ul>
                        <li v-if="$t('language.language') === 'Chinese'" v-for="(item,index) in productType4EN.names" @click="linkENPage" :key="index">{{item}}</li>
                        <li v-if="$t('language.language') === 'English'" v-for="(item,index) in productType4CN.names" @click="linkPage(item)" :key="index">{{item.name}}</li>
                    </ul>
                </div>
                <div class="PCproduct_Centers_right">
                    <img class="PCproductrightimg" v-for="(item,index) in productType4CN.urls" :src="item" :key="index"/>
                </div>
            </div>
        </div>
        <div class="PCproduct_CentersBox">
            <div class="PCproduct_Centers">
                <div class="PCproduct_Centers_left">
                    <p style="font-size:24px;font-weight: 900;color: rgb(82, 111, 150);">{{$t('language.language') === 'Chinese'? 'Removable Cast Partial' : '活動鋼托'}}</p>
                    <span class="lineBox"></span>
                    <ul>
                        <li v-if="$t('language.language') === 'Chinese'" v-for="(item,index) in productType5EN.names" @click="linkENPage" :key="index">{{item}}</li>
                        <li v-if="$t('language.language') === 'English'" v-for="(item,index) in productType5CN.names" @click="linkPage(item)" :key="index">{{item.name}}</li>
                    </ul>
                </div>
                <div class="PCproduct_Centers_right">
                    <img class="PCproductrightimg" v-for="(item,index) in productType5CN.urls" :src="item" :key="index"/>
                </div>
            </div>
        </div>
        <div class="PCproduct_CentersBox">
            <div class="PCproduct_Centers">
                <div class="PCproduct_Centers_left">
                    <p style="font-size:24px;font-weight: 900;color: rgb(82, 111, 150);">{{$t('language.language') === 'Chinese'? 'Attachment' : '精密附件'}}</p>
                    <span class="lineBox"></span>
                    <ul>
                        <li v-if="$t('language.language') === 'Chinese'" v-for="(item,index) in productType6EN.names" @click="linkENPage" :key="index">{{item}}</li>
                        <li v-if="$t('language.language') === 'English'" v-for="(item,index) in productType6CN.names" @click="linkPage(item)" :key="index">{{item.name}}</li>
                    </ul>
                </div>
                <div class="PCproduct_Centers_right">
                    <img class="PCproductrightimg" v-for="(item,index) in productType6CN.urls" :src="item" :key="index"/>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name:'PCproductInfo',
    data(){
        return{
            // productType6CNimg1 - 6,
            productType1CN:{
                names:[
                    {name:'威兰德全瓷',value:'productTypeCNimg1_3',index:18},
                    {name:'Ablaze全瓷',value:'productTypeCNimg1_2',index:0},
                    {name:'易美全瓷',value:'productTypeCNimg1_5',index:17},
                    {name:'LAVA全瓷',value:'productTypeCNimg1_3',index:18},
                ],
                urls:[require('../../assets/PCimg/PCproductImgs/productType1CNimg1.png'),
                      require('../../assets/PCimg/PCproductImgs/productType1CNimg2.jpg'),
                      require('../../assets/PCimg/PCproductImgs/productType1CNimg3.jpg'),
                      require('../../assets/PCimg/PCproductImgs/productType1CNimg4.jpg'),
                      require('../../assets/PCimg/PCproductImgs/productType1CNimg5.jpg'),
                      require('../../assets/PCimg/PCproductImgs/productType1CNimg6.png'),
                ]
            },
            productType2CN:{
                names:[
                    {name:'生物鐳射烤瓷',value:'productTypeCNimg2_1',index:15},
                    {name:'金屬烤瓷',value:'productTypeCNimg2_4',index:14},
                    {name:'馬里蘭橋',value:'productTypeCNimg2_5',index:13},
                    {name:'金屬嵌體',value:'productTypeCNimg2_2',index:19},
                ],
                urls:[require('../../assets/PCimg/PCproductImgs/productType2CNimg1.jpg'),
                      require('../../assets/PCimg/PCproductImgs/productType2CNimg2.png'),
                      require('../../assets/PCimg/PCproductImgs/productType2CNimg3.png'),
                      require('../../assets/PCimg/PCproductImgs/productType2CNimg4.png'),
                      require('../../assets/PCimg/PCproductImgs/productType2CNimg5.jpg'),
                      require('../../assets/PCimg/PCproductImgs/productType2CNimg6.jpg'),
                ]
            },
            productType3CN:{
                names:[
                    {name:'種植導板',value:'productTypeCNimg3_2',index:21},
                    {name:'種植覆盖羲茵',value:'productTypeCNimg3_1',index:22},
                    {name:'種植个性化基台',value:'productTypeCNimg3_3',index:20},
                    {name:'鑄造個性化種植支架',value:'productTypeCNimg3_4',index:23},
                ],
                urls:[require('../../assets/PCimg/PCproductImgs/productType3CNimg1.png'),
                      require('../../assets/PCimg/PCproductImgs/productType3CNimg2.png'),
                      require('../../assets/PCimg/PCproductImgs/productType3CNimg3.png'),
                      require('../../assets/PCimg/PCproductImgs/productType3CNimg4.png'),
                      require('../../assets/PCimg/PCproductImgs/productType3CNimg5.jpg'),
                      require('../../assets/PCimg/PCproductImgs/productType3CNimg6.jpg'),
                ]
            },
            productType4CN:{
                names:[
                    {name:'防磨牙墊',value:'productTypeCNimg4_1',index:2},
                    {name:'止鼾器',value:'productTypeCNimg4_2',index:3},
                    {name:'法蘭克福',value:'productTypeCNimg4_3',index:4},
                    {name:'哈利式保持器',value:'productTypeCNimg4_4',index:6},
                    {name:'螺旋矯正器',value:'productTypeCNimg4_6',index:5},
                    {name:'運動護齒套',value:'productTypeCNimg4_5',index:1},
                ],
                urls:[require('../../assets/PCimg/PCproductImgs/productType4CNimg1.png'),
                      require('../../assets/PCimg/PCproductImgs/productType4CNimg2.png'),
                      require('../../assets/PCimg/PCproductImgs/productType4CNimg3.png'),
                      require('../../assets/PCimg/PCproductImgs/productType4CNimg4.jpg'),
                      require('../../assets/PCimg/PCproductImgs/productType4CNimg5.jpg'),
                      require('../../assets/PCimg/PCproductImgs/productType4CNimg6.jpg'),
                ]
            },
            productType5CN:{
                names:[
                    {name:'德國BPD完美支架',value:'productTypeCNimg5_1',index:10},
                    {name:'純鈦支架',value:'productTypeCNimg5_3',index:12},
                    {name:'維他靈支架',value:'productTypeCNimg5_2',index:11},
                    {name:'全口義齒',value:'productTypeCNimg5_5',index:2},
                    {name:'聚醚醚酮peek支架',value:'productTypeCNimg5_4',index:8},
                    {name:'Snap on smile',value:'productTypeCNimg5_6',index:7},
                ],
                urls:[require('../../assets/PCimg/PCproductImgs/productType5CNimg1.jpg'),
                      require('../../assets/PCimg/PCproductImgs/productType5CNimg2.png'),
                      require('../../assets/PCimg/PCproductImgs/productType5CNimg3.jpg'),
                      require('../../assets/PCimg/PCproductImgs/productType5CNimg4.png'),
                      require('../../assets/PCimg/PCproductImgs/productType5CNimg5.jpg'),
                      require('../../assets/PCimg/PCproductImgs/productType5CNimg6.png'),
                ]
            },
            productType6CN:{
                names:[
                    {name:'精密附件套筒冠',value:'productAnnexObj3',index:24},
                    {name:'精密附件快模式( Snap)',value:'精密附件快模式',index:25},
                    {name:'精密附件栓道式',value:'productAnnexObj2',index:26},
                    {name:'精密附件根帽附著體（scop）',value:'productAnnexObj4',index:27},
                ],
                urls:[require('../../assets/PCimg/PCproductImgs/productType6CNimg1.png'),
                      require('../../assets/PCimg/PCproductImgs/productType6CNimg2.png'),
                      require('../../assets/PCimg/PCproductImgs/productType6CNimg3.jpg'),
                      require('../../assets/PCimg/PCproductImgs/productType6CNimg4.png'),
                      require('../../assets/PCimg/PCproductImgs/productType6CNimg5.jpg'),
                      require('../../assets/PCimg/PCproductImgs/productType6CNimg6.jpg'),
                ]
            },
            productType1EN:{
                names:[
                    'Wieland Zirconia','Ablaze Zirconia','IPS e.max','Lava Zirconia'
                ],

                urls:[require('../../assets/PCimg/PCproductImgs/productType1CNimg1.png'),
                      require('../../assets/PCimg/PCproductImgs/productType1CNimg2.jpg'),
                      require('../../assets/PCimg/PCproductImgs/productType1CNimg3.jpg'),
                      require('../../assets/PCimg/PCproductImgs/productType1CNimg4.jpg'),
                      require('../../assets/PCimg/PCproductImgs/productType1CNimg5.jpg'),
                      require('../../assets/PCimg/PCproductImgs/productType1CNimg6.png'),
                ]
            },
            productType2EN:{
                names:[
                    'Laser Print ','Ablaze Zirconia','Maryland Bridge','Metal Inlay'
                ],
                urls:[require('../../assets/PCimg/PCproductImgs/productType2CNimg1.jpg'),
                      require('../../assets/PCimg/PCproductImgs/productType2CNimg2.png'),
                      require('../../assets/PCimg/PCproductImgs/productType2CNimg3.png'),
                      require('../../assets/PCimg/PCproductImgs/productType2CNimg4.png'),
                      require('../../assets/PCimg/PCproductImgs/productType2CNimg5.jpg'),
                      require('../../assets/PCimg/PCproductImgs/productType2CNimg6.jpg'),
                ]
            },
            productType3EN:{
                names:[
                    'Surgical Implant Guide','Implant Overdenture','Custom Abutment','Casting Superstructure For Removable Denture'
                ],
                urls:[require('../../assets/PCimg/PCproductImgs/productType3CNimg1.png'),
                      require('../../assets/PCimg/PCproductImgs/productType3CNimg2.png'),
                      require('../../assets/PCimg/PCproductImgs/productType3CNimg3.png'),
                      require('../../assets/PCimg/PCproductImgs/productType3CNimg4.png'),
                      require('../../assets/PCimg/PCproductImgs/productType3CNimg5.jpg'),
                      require('../../assets/PCimg/PCproductImgs/productType3CNimg6.jpg'),
                ]
            },
            productType4EN:{
                names:[
                    'Night Guard','Snoring Guard ','Frankel Appliance','Hawley Retainer','Expansion Plate','Sport Guard'
                ],
                urls:[require('../../assets/PCimg/PCproductImgs/productType4CNimg1.png'),
                      require('../../assets/PCimg/PCproductImgs/productType4CNimg2.png'),
                      require('../../assets/PCimg/PCproductImgs/productType4CNimg3.png'),
                      require('../../assets/PCimg/PCproductImgs/productType4CNimg4.jpg'),
                      require('../../assets/PCimg/PCproductImgs/productType4CNimg5.jpg'),
                      require('../../assets/PCimg/PCproductImgs/productType4CNimg6.jpg'),
                ]
            },
            productType5EN:{
                names:[
                    'Bego Framework','Titanium Framework','Vitallium 2000 Framework','Full Denture','Peek Framework','Snap on smile'
                ],
                urls:[require('../../assets/PCimg/PCproductImgs/productType5CNimg1.jpg'),
                      require('../../assets/PCimg/PCproductImgs/productType5CNimg2.png'),
                      require('../../assets/PCimg/PCproductImgs/productType5CNimg3.jpg'),
                      require('../../assets/PCimg/PCproductImgs/productType5CNimg4.png'),
                      require('../../assets/PCimg/PCproductImgs/productType5CNimg5.jpg'),
                      require('../../assets/PCimg/PCproductImgs/productType5CNimg6.png'),
                ]
            },
            productType6EN:{
                names:[
                    'Telescope Coping','Telescope Coping','Slot Attachment','Slot Attachment'
                ],
                urls:[require('../../assets/PCimg/PCproductImgs/productType6CNimg1.png'),
                      require('../../assets/PCimg/PCproductImgs/productType6CNimg2.png'),
                      require('../../assets/PCimg/PCproductImgs/productType6CNimg3.jpg'),
                      require('../../assets/PCimg/PCproductImgs/productType6CNimg4.png'),
                      require('../../assets/PCimg/PCproductImgs/productType6CNimg5.jpg'),
                      require('../../assets/PCimg/PCproductImgs/productType6CNimg6.jpg'),
                ]
            }

        }
    },
    methods:{
        linkPage(row){
            // const { href } = this.$router.resolve({
            //     path: "/PCproductDet",
            //     query:{...row}
            // });
            // window.open(href, "_blank")
            this.$router.push({name:'PCproductDet',query:{...row}})
        },
        linkENPage(name,index,value){
            // const { href } = this.$router.resolve({
            //     path: "/PCproductDet",
            // });
            // window.open(href, "_blank")
            this.$router.push({name:'PCproductDet',query:{index}})
        }
    }
}
</script>
<style lang="less">
.PCproduct_header{
    width: 100%;
    height: 364px;
    margin-bottom: 100px;
}
.PCproduct_Title{
    margin: 0 auto;
    padding: 5px 30px;
    font-size: 28px;
    color: white;
    width: 1229px;
    background: rgb(96, 122, 157) none no-repeat left top;
    .PCproduct_Title1{
        display: inline-block;
        text-align: left; 
        width:40%
    }
    .PCproduct_Title2{
        display: inline-block;
        text-align: right; 
        text-align: center;
        width:40%
    }
}
.PCproduct_CentersBox{
    width: 1229px;
    margin: 0 auto;
}
.PCproduct_Centers{
     margin:30px 0 30px;
    display: flex;
    justify-content: space-between;
}
.PCproduct_Centers_left{
    padding-top: 20px;
    width: 400px;
    background:#DEE0E2;
    cursor: pointer;
    .lineBox{
        display: inline-block;
        height: 2px;
        width: 250px;
        background: rgb(82, 111, 150);
    }
    p{
        margin:0 auto;
        color: rgb(82, 111, 150);
        // width:250px;
        // border-bottom: 2px solid  rgb(82, 111, 150);
    }
    ul{
        font-size:22px;
        li{
            margin:20px 0;
        }
        li:hover{
            color:#72C1D8;
        }
    }
}
.PCproduct_Centers_right{
    width: 720px;
    .PCproductrightimg{
        margin-left:10px;
        margin-bottom:10px;
        width: 230px;
        height:180px;
    }
}
</style>


