<template>
    <div>
        <van-swipe class="my-swipe" :autoplay="3000" indicator-color="#469CB7">
            <van-swipe-item>
                <img class="lunboBox" v-if="$t('language.language') === 'Chinese'" src="../../assets/MobileImg/ENluobo1.jpg" alt="banner1">
                <img class="lunboBox" v-else src="../../assets/MobileImg/CNluobo1.png" alt="banner1">
            </van-swipe-item>
            <van-swipe-item>
                <img class="lunboBox" v-if="$t('language.language') === 'Chinese'" src="../../assets/MobileImg/ENluobo2.jpg" alt="banner1">
                <img class="lunboBox" v-else src="../../assets/MobileImg/CNluobo2.jpg" alt="banner1">
            </van-swipe-item>
            <van-swipe-item><img class="lunboBox" src="../../assets/MobileImg/luobotu.png" alt="banner3"></van-swipe-item>
        </van-swipe>
        <video  v-if="$t('language.language') === 'Chinese'"
                muted
                autoplay
                x5-playsinline="true"
                webkit-playsinline="true" 
                playsinline="true" 
                style="background:#000" 
                controlslist="nodownload" 
                poster="../../assets/MobileImg/VideoCover.jpg" 
                src="../../assets/videoFile/ENvideolunbo.mp4" 
                width="100%" 
                controls="controls"
                class="video_url_play">
        </video>
        <video v-else 
                muted
                autoplay
                x5-playsinline="true"
                webkit-playsinline="true" 
                playsinline="true" 
                style="background:#000" 
                controlslist="nodownload" 
                poster="../../assets/MobileImg/VideoCover.jpg" 
                src="../../assets/videoFile/CNvideolunbo.mp4" 
                width="100%" 
                controls="controls"
                class="video_url_play">
        </video>
        <div class="SectionImg1">
            <img class="SectionImg1" src="../../assets/MobileImg/sectionImg1.png" alt="">
        </div>
        <div class="SectionImg2">
            <img class="SectionImg2" src="../../assets/MobileImg/sectionImg2.jpg" alt="">
        </div>
    </div>
</template>
<script>
export default {
    name:'home',
    data(){
        return{

        }
    }
}
</script>
<style lang="less">
    .testRem{
        font-size: 1rem;
    }
    .lunboBox{
        width: 100%;
        height: 11rem;
    }
    .video_url_play{
        object-fit:fill;  
        height: 13rem;
    }
    .SectionImg1{
        width: 100%;
        height: 9rem;
    }
    .SectionImg2{
        width: 100%;
        height: 11rem;
    }

</style>



