module.exports = {
    language:{
        language:'English'
    },
    menuObj:{
        menuName1:'首頁',
        menuName2:'新技術',
        menuName3:'全瓷系列',
        menuName4:'烤瓷系列',
        menuName5:'種植修復',
        menuName6:'矯正裝置',
        menuName7:'活動修復',
        menuName8:'企業背景',
        menuName9:'聯繫我們',
        menuName10:'精密附件',
        menuName11:'產品介紹',
    },
    footerObj:{
        footerName1:'首頁',
        footerName2:'新技術',
        footerName3:'產品',
        footerName4:'电话',
    },
    productObj:{
        productType1:'全瓷系列',
        productType2:'烤瓷系列',
        productType3:'種植修復',
        productType4:'矯正裝置',
        productType5:'活動修復',
    },
    ContactUsObj:{
        Contact1:'聯繫電話：+852  4613  6101+852  9323  2240',
        Contact2:'微信：18923831515',
        Contact3:'郵箱：cnpassport.alibaba.com',
        Contact4:'位置地點:香港九龍旺角彌敦道728號-730號',
    }
}