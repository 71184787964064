<template>
    <div>
        <div class="PctechnologyBG">
            <img class="PctechnologyBG" src="../../assets/PCimg/homeFileImg/PctechnologyBG.png" alt="">
        </div>
        <div class="Pctechnolog_centerBox">
            <div class="Pctechnolog_img1">
                <img v-if="$t('language.language') === 'Chinese'" class="Pctechnolog_img1" src="../../assets/PCimg/homeFileImg/PctechnologyENimg1.png" alt="">
                <img v-else class="Pctechnolog_img1" src="../../assets/PCimg/homeFileImg/PctechnologyCNimg1.jpg" alt="">
            </div>
            <div>
                <img v-if="$t('language.language') === 'Chinese'" class="Pctechnolog_img2" src="../../assets/PCimg/homeFileImg/PctechnologyENimg2.jpg" alt="">
                <img v-else class="Pctechnolog_img2" src="../../assets/PCimg/homeFileImg/PctechnologyCNimg2.jpg" alt="">
                <img v-if="$t('language.language') === 'Chinese'" class="Pctechnolog_img2" src="../../assets/PCimg/homeFileImg/PctechnologyENimg3.jpg" alt="">
                <img v-else class="Pctechnolog_img2" src="../../assets/PCimg/homeFileImg/PctechnologyCNimg3.jpg" alt="">
            </div>
        </div>
        <div class="Pctechnolog_ENimg4">
            <img v-if="$t('language.language') === 'Chinese'" class="Pctechnolog_ENimg4" src="../../assets/PCimg/homeFileImg/PctechnologyENimg4.png" alt="">
            <img v-else class="Pctechnolog_ENimg4" src="../../assets/PCimg/homeFileImg/PctechnologyCNimg4.png" alt="">
        </div>
        <div class="Pctechnolog_img5">
             <img class="Pctechnolog_img5" src="../../assets/PCimg/homeFileImg/PctechnologyENimg5.png" alt="">
        </div>
    </div>
</template>
<script>
export default {
    name:'Pctechnologypage',
    data(){
        return{

        }
    }
}
</script>
<style>
.PctechnologyBG{
    width: 100%;
    height: 364px;
    margin-bottom: 30px;
}
.Pctechnolog_centerBox{
    display: flex;
    justify-content: space-between;
}
.Pctechnolog_img1{
    width: 1050px;
    height: 1400px;
}
.Pctechnolog_img2{
     width: 800px;
    height: 700px;
}
.Pctechnolog_ENimg4{
     width: 1900px;
    height: 125px;
}
.Pctechnolog_img5{

    width: 100%;
}
</style>


